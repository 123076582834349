import deLocale from "element-plus/dist/locale/de";

export default {
  ...deLocale,
  termsAccept: "Ich stimme den  {0}  zu.",
  tos: "AGB",
  privacyAccept: "Ich stimme der  {0}  zu.",
  privacyPolicy: "Datenschutzerklärung",
  resetPassword: "Passwort zurücksetzen",
  usernamePasswordRequired:
    "Bitte geben Sie einen Nutzernamen und ein Passwort ein.",
  passwordTooShort: "Das Passwort muss mindestens 8 Zeichen lang sein.",
  wrongCredentials: "Es wurde kein Account mit den angegebenen Daten gefunden.",
  accountStructureItem: "Abteilung",
  addRole: "Rolle hinzufügen",
  allUserRelatedDeleteText:
    "Hiermit werden alle nutzerrelevanten Daten von unwiderruflich gelöscht.",
  assignRoleModals: "Rolle zuweisen",
  canBeDownloadedText: "können unter abgeschlossen heruntergeladen werden",
  changePassword: "Passwort ändern",
  changingPasswordFor: "Passwort für den Nutzer ändern",
  confirmDeleteText: "Bitte geben Sie den Nutzernamen zum Bestätigen ein:",
  confirmDeteleBtnText: "Ich habe verstanden, löschen Sie den Nutzer",
  confirmPassword: "Passwort wiederholen",
  createUser: "Nutzer erstellen",
  currentPassword: "Aktuelles Passwort",
  customId: "Nutzerdefinierte ID",
  dataPrivacyHeader: "Datenprivatsphäre",
  dataPrivacyText: "Abschnittstext (Inhalt wird folgen)",
  dataPrivacyTitle: "Abschnittsüberschrift",
  dataRelatedToMe: "Über mich gespeicherte Daten einsehen",
  dataRelatedToMeTitle: "Gespeicherte Daten",
  deleteText: "Sind Sie sicher, dass Sie das löschen möchten",
  downloadDataRelatedToMe: "Gespeicherte Daten herunterladen",
  fileWithUserInformation: "Datei mit Nutzerinformationen",
  forgotPassword: "Passwort vergessen?",
  forgotPasswordText:
    "Bitte geben Sie ihre E-Mail-Adresse ein, um einen Link zum zurücksetzen ihres Passwortes zu erhalten.",
  importUsers: "Nutzer importieren",
  newHere: "Neu hier?",
  newPassowrd: "Neues Passwort",
  newPasswordShort: "Neues Passwort zu kurz",
  password: "Passwort",
  passwordCantBeEmpty: "Das Passwort darf nicht leer sein",
  passwordsNotMatch: "Passwörter stimmen nicht überein",
  pickUser: "Wählen Sie einen Nutzer aus",
  pleaseFillUpAllFields: "Bitte füllen Sie alle Felder aus",
  pleaseTypeNewPassword: "Bitte geben Sie Ihr neues Passwort ein",
  policyAcceptance: "Annahme der Richtlinien",
  policyAcceptanceSave: "Akzeptieren",
  acceptPolicies: "Bitte die Richtlinien akzeptieren",
  profile: "Profil",
  role: "Rolle",
  roleExpiresOn: "Die Rolle verfällt am",
  saveDataAboutMe: "über mich gespeicherte Daten",
  signIn: "Anmelden",
  termsConditionsHeader: "Allgemeine Geschäftsbedingungen",
  termsConditionsText: "Abschnittstext (Inhalt wird folgen)",
  termsConditionsTitle: "Abschnittsüberschrift",
  typeInOne: "Geben Sie einen für alle ein",
  username: "Nutzername",
  firstname: "Vorname",
  lastname: "Nachname",
  usernameMail: "Nutzername oder E-Mail-Adresse",
  userNotes: "Notizen",
  userProfile: "Nutzerprofil",
  userStatus: "Nutzer Status",
  wrongCurrentPassword: "Falsches aktuelles Passwort",
  fillAllFields: "Bitte alle notwendigen Felder ausfüllen",
  wrongUsernameOrPassword: "Nutzername oder Passwort sind falsch",
  minimumLength: "Mindestlänge 8",
  atLeastOneBigAndSmall: "Mindestens 1 kleiner und 1 großer Buchstabe",
  passwordNotEqualUsernameAndEmail:
    "Passwort ist nicht identisch mit dem Nutzernamen und der E-Mail-Adresse",
  registrationKey: "Registrierungsschlüssel",
  registration: "Registrierung",
  notAnEmailAdress: "Bitte geben Sie eine valide E-Mail-Adresse ein.",
  company: "Firma",
  companyHint: "Firma",
  birthday: "Geburtstag",
  birthdayHint: "Geburtstag",
  birthyear: "Geburtsjahr",
  accountName: "Kontoname",
  accountType: "Kontoart",
  accountAddress: "Adresse des Kontoinhabers",
  logo: "Logo",
  address: "Adresse",
  contry: "Country",
  zipCode: "PLZ",
  lessThan: "weniger als 1mb",
  defaultLanguage: "Standardsprache",
  accountStatus: "Kontostatus",
  accountNotes: "Kontonotizen",
  updateAccount: "Konto aktualisieren",
  fileToImport: "Account notes",
  importStructure: "Struktur importieren",
  addRootAccount: "Basis Level Account hinzufügen",
  dateRange: "Zeitspanne",
  usersPerMonth: "Monatliche Nutzer",
  usersPerMonthGlobal: "Monatliche Nutzer Global",
  usersAdded: "Hinzugefügte Nutzer",
  usersDeleted: "Gelöschte Nutzer",
  usersTotal: "Nutzer Gesamt",
  usersBilling: "Nutzer Rechnungsrelevant",
  city: "Stadt",
  users: "Nutzer",
  coursesAvailable: "Verfügbare Kurse",
  started: "Gestartet",
  completed: "Abgeschlossen",
  openMandatoryAssignments: "Offene Pflichtzuweisungen",
  mandatoryAssignments: "Pflichtzuweisungen",
  dueMandatoryCompletions: "Fällige Pflichtabschlüsse",
  of: "von",
  accountOverview: "Accountübersicht",
  selectAccount: "Wähle einen Account",
  noAccountsAvailable: "Keine Accounts verfügbar",
  completionRate: "Abschlussquote",
  averageResult: "Durchschnittsergebnis",
  courseOverview: "Übersicht der Kurse",
  reportCoursesSearchPlaceholder: "Suche einen Kurs",
  completedLastWeeks: "Kursabschlüsse in den letzten {number} Wochen",
  completedLastMonths: "Kursabschlüsse in den letzten {number} Monaten",
  executionTime: "Verteilung nach Ausführungszeit (min)",
  completionLevel: "Verteilung nach Abschlusspunkten",
  validTo: "Gültig bis",
  knowledge: "WISSEN",
  toolbox: "TOOLBOX",
  lessonCompleted: "Lektion abgeschlossen",
  lessonFailed: "Lektion nicht bestanden",
  repeatLesson: "Bitte wiederholen Sie die Lektion, um fortzufahren",
  congratulations: "Herzlichen Glückwunsch!",
  courseCompleted: "Kurs abgeschlossen",
  percentCompleted: "Sie haben $successRate% der Fragen richtig beantwortet.",
  downloadCertificate:
    "Sie können jederzeit Ihr Zertifikat unter „Zertifikate“ im Profil herunterladen.",
  trueFalse: "Richtig oder Falsch?",
  seconds: "Sek.",
  noChoiceText: "Schade, zu spät.",
  right: "Richtig!",
  wrong: "Falsch",
  progress: "$current von $total",
  addCourseContent: "Kursinhalte hinzufügen",
  startCourseEditor: "Kurseditor starten",
  copyCourse: "Kurs kopieren",
  courseCopyStarted: "Kopiervorgang gestartet",
  courseCopySuccess: "Kurs wurde erfolgreich kopiert",
  transferCourse: "Kurs transferieren",
  systemUrl: "System-URL",
  addSemicolon: "Fügen Sie Beschriftungen durch ein Semikolon getrennt hinzu",
  addToProgram: "Merken",
  allAccounts: "Alle Konten",
  certificate: "Zertifikat",
  certificates: "Zertifikate",
  completed: "Abgeschlossen",
  contentIsLoaded: "Inhalt ist geladen",
  contentManager: "Inhaltsmanager",
  courseCompleted: "Kurs abgeschlossen",
  courseContent: "Kursinhalt",
  courseFormat: "Kursformat",
  coverPhoto: "Titelbild",
  editCoverPhoto: "Titelbild ändern",
  addCoverPhoto: "Titelbild hinzufügen",
  additionalDocuments: "Zusätzliche Unterlagen",
  editAdditionalDocuments: "Zusätzliche Unterlagen ändern",
  addAdditionalDocuments: "Zusätzliche Unterlagen hinzufügen",
  documents: "Unterlagen",
  courseLables: "Kursetiketten",
  courseLanguage: "Kurssprache",
  courseNotes: "Kursnotizen",
  courseStructure: "Struktur",
  courseTags: "Kurstags",
  courseTitle: "Kursname",
  createCourse: "Kurs erstellen",
  curator: "KURATOR",
  description: "Beschreibung",
  details: "Details",
  dueDate: "Fällig bis",
  duration: "Dauer",
  exportStatistics: "Statistiken exportieren",
  learningStandard: "Lernstandard",
  licenses: "Lizenzen",
  mandatory: "Pflichtkurs",
  noAssessment: "Momentan stehen keine Bewertungen zur Verfügung.",
  noCoursesAssigned:
    "Ihrem Account sind keine Kurse zugeordnet. Bitte besuchen Sie die ",
  noCoursesAvailable: "Momentan stehen keine Kurse zur Verfügung.",
  noDueDate: "Kein Fälligkeitsdatum",
  overview: "Übersicht",
  page: "",
  pickAnAccount: "Wähle ein Konto",
  printCertificate: "Zertifikat drucken",
  publishCourse: "Kurs veröffentlichen",
  publishedToAccount: "Veröffentlicht in Konto",
  relatedCourses: "Ähnliche Kurse",
  remove: "Entfernen",
  removeFromProgram: "Aus Programm entfernen",
  singleAccount: "Einzelnes Konto",
  startCourse: "Starten",
  successMessageOne: "Glückwunsch, Sie haben den Kurs ",
  successMessageThree:
    "Jetzt können Sie Ihr Abschlusszertifikat herunterladen oder ausdrucken. ",
  successMessageTwo: " erfolgreich abgeschlossen!",
  tags: "Stichworte",
  unpublishCourse: "Veröffentlichung aufheben",
  updateCourseContent: "Kursinhalte hochladen",
  updateCourse: "Kurs aktualisieren",
  youDidntfinishCourse: "Sie haben noch keinen Kurs abgeschlossen.",
  courseVisibleFor: "Kurs ist sichtbar für",
  accountStructureItem: "Abteilung",
  courseAssigmentOptions: "Zuweisungsoptionen",
  assignCourse: "Kurs zuweisen",
  pickACourse: "Wähle einen Kurs aus",
  noDocumentsAvailable: "Keine Unterlagen vorhanden",
  nothingRelated: "Keine ähnlichen Kurse vorhanden",
  nothingWritten: "nichts eingetragen",
  noTagsAvailable: "Keine Stichwörter vorhanden",
  alreadyGlobal: "Der Kurs ist bereits weltweit veröffentlicht.",
  questionsanswered: "Fragen",
  completedOn: "Abschluss am",
  locale: "de-DE",
  quizAuthorizationContent: "Quiz Autorisierung",
  courseNotPublished: "Der Kurs ist derzeit nicht veröffentlicht",
  courseNotPublishedForAccount:
    "Der Kurs ist derzeit nicht für das Konto $account veröffentlicht",
  clickToPublishCourse: "Kurs veröffentlichen",
  visible: "Sichtbar",
  userIsNotContentManager:
    "Sie sind kein Content Manager und können deshalb den Kursinhalt nicht bearbeiten.",
  assignmentOpen: "Offen",
  assignmentStarted: "Gestartet",
  assignmentCompleted: "Abgeschlossen",
  dueDatePassed: "Fälligkeitsdatum überschritten",
  exitCourse: "Kurs abbrechen",
  markAsFinished: "Kurs abgeschlossen",
  assignToAllUsers: "Kurs allen Nutzern im Konto zuweisen",

  learningPoints: "Lernpunkte",
  learningPointsAbbreviation: "LP",
  back: "Zurück",
  next: "Weiter",
  responsesMissing: "Bitte schließen Sie alle Punkte ab, bevor Sie fortfahren",

  configureLogging: "Protokollierung konfigurieren",
  createAccount: "Account anlegen",
  changeAccount: "Account ändern",
  deleteAccount: "Account löschen",
  importAccountStructure: "Accountstruktur importieren",
  createUser: "Nutzer erstellen",
  importUser: "Importieren",
  changeUser: "Nutzer ändern",
  deleteUser: "Nutzer löschen",
  assignUserRole: "Rolle zuweisen",
  resetPassword: "Passwort zurücksetzen",
  createCourse: "Kurs erstellen",
  changeCourse: "Kurs ändern",
  addQuizContent: "Quizinhalt hinzufügen",
  changeQuizContent: "Quizinhalt ändern",
  addContent: "Kursinhalt hinzufügen",
  changeContent: "Kursinhalt ändern",
  deleteCourse: "Kurs löschen",
  deleteContent: "Kursinhalt löschen",
  publishCourse: "Kurs veröffentlichen",
  unpublishCourse: "Veröffentlichung aufheben",
  assignCourse: "Kurs zuweisen",
  unassignCourse: "Kurszuweisung aufheben",
  systemMessages: "Systemnachrichten",
  learningManagement: "Lernmanagement",
  courseManagement: "Kursmanagement",
  userManagement: "Nutzermanagement",
  organizationStructure: "Organisationsstruktur",
  accountManagement: "Kontenmanagement",
  system: "System",
  learningManagementDetail: "Lernpläne und Kurszuweisung",
  courseManagementDetail: "Kursinhalte verwalten",
  userManagementDetail: "Nutzer verwalten und Passwörter zurücksetzen",
  organizationStructureDetail: "Organisationsstruktur definieren",
  accountManagementDetail: "Konten verwalten",
  logsDetail: "System-Logs und Einstellungen",
  userCreateSuccess: "Nutzer erfolgreich erstellt",
  createOtherUser: "Einen weiteren Nutzer hinzufügen",
  backToUserOverview: "Zurück zur Nutzerübersicht",
  importMoreUsers: "Weitere Nutzer importieren",
  currentRoles: "Aktuelle Rollen",
  numberOfRoles: "Anzahl Rollen",
  numberOfUsers: "Nutzer",
  deleteAccountMessage:
    "Bitte bestätigen Sie, dass Sie den Account '$account' löschen wollen.",
  showStatistics: "Statistiken",
  appendAccount: "Account an $account anhängen.",
  courseAssignments: "Zugeteilt",
  courseAssignmentsForCourse: "Kurszuweisungen für $course",
  mandatoryAssignments: "Pflichtkurszuweisungen",
  assignments: "Kurszuweisungen",
  isMandatory: "Pflichtkurs",
  dueDateAssignments: "Mit Frist",
  dueDatePassed: "Fällig",
  userOrAccount: "Nutzer oder Abteilung",
  expiryDate: "Ablaufdatum",
  deletePublication: "Veröffentlichung löschen",
  deletePublicationMessage:
    "Sind sie sicher, dass Sie die Veröffentlichung für den Account $account löschen wollen?",
  orgStructure: "Abteilung",
  type: "Typ",
  downloadTemplate: "Vorlage Herunterladen",
  published: "Veröffentlicht",
  scorm: "SCORM",
  quiz: "Quiz",
  staticFile: "PDF",
  editPublication: "Veröffentlichung bearbeiten",
  pleaseDefineCourseContent:
    "Bitte definieren Sie den Kursinhalt, bevor Sie ihn veröffentlichen.",
  usernameMissing: "Nutzername fehlt",
  usernameError: "Nutzername kann nur aus alphanumerischen Zeichen bestehen",
  emailMissing: "E-Mail-Adresse fehlt",
  emailError: "Keine valide E-Mail-Adresse",
  passwordMissing: "Passwort fehlt",
  passwordError:
    "Das Passwort muss mindestens 8 Zeichen lang sein sowie einen Groß- und einen Kleinbuchstaben enthalten.",
  registrationKeyManagement: "Registrierungsschlüssel",
  registrationKeyManagementDetail: "Verwalten von Registrierungsschlüsseln",
  addRegistrationKey: "Registrierungsschlüssel hinzufügen",
  registrationLink: "Registrierungslink",
  user_upload_maximum: "Bis zu 1000 User pro Upload",
  userState: "Nutzerstatus",
  userStateDetail: "Zugangsberechtigung und Pflichtkurse.",
  testsPassed: "Pflichtkurse abgeschlossen",
  access: "Zugang",
  downloadReport: "Bericht",
  success: "Erfolgreich gespeichert",
  warning: "Warnung",
  error: "Fehler",
  removedFromProgram: "Kurs wurde aus ihrem Programm entfernt",
  addedToProgram: "Kurs wurde zu Ihrem Programm hinzugefügt",
  courseCreated: "Kurs wurde angelegt",
  courseNotCreated: "Kurs wurde nicht angelegt",
  fillUpFields: "Bitte alle Pflichtfelder ausfüllen",
  cookieNotAvailable:
    "Ihre Sitzung ist nicht mehr gültig, bitte melden Sie sich erneut an",
  courseNotFinished: "Keine Abschlussinformation für den Kurs vorhanden",
  courseAborted: "Kurs wurde abgebrochen",
  correctAnswer: "Richtige Antwort",
  wrongAnswer: "Falsche Antwort",
  courseStarted: "Kurs wurde gestartet",
  testFailed: "Test nicht bestanden",
  courseCompleted: "Kurs wurde erfolgreich abgeschlossen",
  imageTooLarge: "Datei sollte weniger als 2 MB groß sein",
  wrongFormat: "Falsches Format",
  passwordReset:
    "Ein Link, um das Passwort zurückzusetzen, wurde an die angegebene E-Mail-Adresse gesendet",
  passwordChanged: "Passwort wurde geändert",
  accountAdded: "Account wurde hinzugefügt",
  accountUpdated: "Account wurde aktualisiert",
  accountDeleted: "Account wurde gelöscht",
  userImported: "Nutzer wurden importiert",
  userNotImported: "Nutzer wurden nicht importiert",
  loggingConfigSaved: "Die Log-Konfiguration wurde gespeichert",
  userDeleted: "Nutzer wurde gelöscht",
  userAdded: "Nutzer wurde angelegt",
  userUpdated: "Nutzer wurde aktualisiert",
  GDPRLoaded: "GDPR ist geladen",
  cookiesDisabled: "Sie haben Cookies deaktiviert",
  wrongCredentials: "Falsche Anmeldedaten",
  fileUploaded: "Ihre Datei wurde hochgeladen",
  courseContentUpdated: "Kursinhalt wurde aktualisiert",
  coursePublished: "Kurs wurde veröffentlicht",
  courseUnPublished: "Die Veröffentlichung des Kurses wurde aufgehoben.",
  serverUnreachable: "Server ist nicht erreichbar",
  courseContentAdded: "Kursinhalt wurde erfolgreich hinzugefügt.",
  user_upload_exceeded:
    "Zu viele Nutzer. Es werden maximal 1000 Nutzer pro Upload unterstützt.",
  selectAccounts: "Account auswählen",
  selectAccountToContinue: "Zum Fortfahren Account auswählen",
  accountOverview: "Accountübersicht",
  users: "Nutzer",
  courses: "Kurse",
  coursesCompleted: "Abgeschlossene Kurse",
  completedCoursesPerUser: "Abgeschlossene Kurse pro Nutzer",
  learningHours: "Lernstunden",
  learningHoursPerUser: "Lernstunden pro Nutzer",
  courseOverview: "Kursübersicht",
  assigned: "Zugewiesen",
  courseDetails: "Details",
  selectCourse: "Kurs auswählen",
  type: "Typ",
  dueDate: "Bis",
  mandatory: "Pflichtkurs",
  completionRate: "Abschlussquote",
  averageScore: "Testergebnis",
  duration: "Dauer",
  averageDuration: "Durchschnittsdauer",
  account: "Konto",
  accountName: "Accountname",
  accounts: "Accounts",
  active: "Aktiv",
  admin: "Admin",
  all: "Alle",
  not_found: "Die Seite existiert nicht",
  assessmentStatistics: "Bewertungsstatistiken",
  assignedUsersOnly: "Nur zugewiesene Nutzer",
  autoIndividual: "Automatisch, individuell",
  cancel: "Abbrechen",
  cardImage: "Kartenbild",
  close: "Schließen",
  completed: "Abgeschlossen",
  confirm: "Bestätigen",
  reset: "Zurücksetzen",
  country: "Land",
  course: "Kurs",
  courseDescription: "Kursbeschreibung",
  courseDocuments: "Kursunterlagen",
  courses: "Kurse",
  courseStructure: "Kursstruktur",
  dataProtection: "Datenschutz",
  defaultLanguage: "Standardsprache",
  deleteText: "Sind Sie sicher, dass Sie das löschen möchten",
  delete: "Löschen",
  dragAndDrop: "Drag & Drop / Klicken zum Hinzufügen",
  dueDate: "Fällig am",
  email: "E-Mail",
  empty: "Leer",
  exit: "Abmelden",
  expiresOn: "Verfällt am",
  fileImport: "Zu importierende Datei",
  image: "Bild",
  inactive: "Inaktiv",
  language: "Sprache",
  logo: "Logo",
  mandatory: "Pflichtkurs",
  monthsLabels: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  myAccount: "Mein Konto",
  myProfile: "Mein Profil",
  myProgram: "Mein Programm",
  myRoles: "Meine Rollen",
  never: "Nie",
  no: "Nein",
  none: "Keine",
  notFound: "Nicht gefunden",
  phoneNumber: "Telefonnummer",
  playImage: "Bild wiedergeben",
  publishingExpiresOn: "Veröffentlichung verfällt am",
  publishTo: "Veröffentlichen in",
  reports: "Bericht",
  save: "Speichern",
  search: "Suchen...",
  status: "Status",
  structureItem: "Abteilung",
  termsAndConditions: "Geschäftsbedingungen",
  unlimited: "Unbegrenzt",
  update: "Aktualisieren",
  user: "Nutzer",
  visibility: "Konto-Sichtbarkeit",
  yes: "Ja",
  info: "Info",
  alert: "Alarm",
  criticalAlert: "Kritischer Alarm",
  navigation: "Navigation",
  note: "Hinweis",
  userId: "Nutzer ID",
  organizationalStructure: "Organisationsstruktur",
  created: "Erstellt am",
  new: "Neu",
  noResults: "Keine Ergebnisse",
  noData: "Keine Daten",
  uploadFile: "Datei hochladen",
  id: "ID",
  name: "Name",
  append: "Hinzufügen",
  edit: "Bearbeiten",
  format: "Format",
  years: "Jahre",
  months: "Monate",
  weeks: "Wochen",
  days: "Tage",
  seconds: "Sekunden",
  minutes: "Minuten",
  hours: "Stunden",
  date: "Datum",
  message: "Nachricht",
  fax: "Fax",
  genericError:
    "Es ist ein Fehler aufgetreten, versuchen Sie, die Seite neu zu laden. Falls der Fehler bestehen bleibt, kontaktieren Sie den Systemadministrator.",
  action: "Aktion",
  courseDisclaimerHeader: "Kurs Information",
  courseDisclaimerText:
    "Weitere Schulungen finden Sie im Bereich {0}. Wenn Sie einen Kurs starten oder merken, wird dieser automatisch zu Ihrem Programm hinzugefügt.",
  courseDisclaimerButton: "Nicht mehr anzeigen",

  // TODO
  roles: "Rollen",
  properties: "Eigenschaften",
  property: "Eigenschaft",
  assignUserProperty: "Eigenschaft zuweisen",
  userProperties: "Eigenschaften des Nutzers",
  addTagToUser: "Eigenschaft zuweisen",
  showMoreFilters: "Mehr Filter anzeigen",
  assigned: "Zugewiesen",
  unassigned: "Nicht zugewiesen",
  assignedOrUnassigned: "Zugewiesen/Nicht zugewiesen",
  emailAboutChange: "Benachrichtigung per E-Mail versenden",
  showLessFilters: "Filter ausblenden",
  clearFilters: "Filter entfernen",
  no_changes_assignment_button: "Keine Änderungen",
  assignment: "Zuweisungen",

  upload_picture: "Hochladen",
  take_picture: "Aufnehmen",
  access_card: "Zugangskarte",

  assign_course: "Kurs zuweisen",
  add_assignment_to_users: "an $users zuweisen",
  remove_assignments_from_users: "von $users Zuweisung entfernen",
  update_assignments_for_users: "für $users Zuweisung updaten",
  create_property: 'Eigenschaft "$property" erstellen',
  non_mandatory: "Kein Pflichtkurs",
  department: "Abteilung",
  user_details: "Benutzerdaten",
  download_file: "Datei Herunterladen",
  your_browser_not_support_this_content:
    "Dein Browser unterstützt dieses Dateiformat nicht.",
  browser_not_support_pdf:
    "Your browser can't display pdf files. Scroll down to download the pdf instead.",
  upload_file: "Datei hochladen",
  deleteDocument: "Dokument löschen",
  deleteDocumentConfirmation:
    "Sind sie sich sicher, dass sie $document löschen wollen?",
  video_unsupported: "Dieser Browser unterstützt kein HTML5 video.",
  confirm: "Bestätigen",
  confirmed: "Bestätigt",
  multiselect: "Auswahl mehrerer Eigenschaften zulassen",
  profile_image: "Profilbild",
  property_type: "Eigenschaften Typ",
  account_details: "Account details",
  remove_assigned_properties: "Zugewiesene Eigenschaften entfernen",
  mandatory_user_properties: "Obligatorische Benutzereigenschaften",

  select_property_first: "Wählen sie eine Eigenschaft aus um fortzufahren.",
  selected_property_already_mandatory:
    "Die ausgewählte Eigenschaft ist bereits obligatorisch.",
  confirm_remove_assigned_properties:
    "Bitte bestätigen sie dass sie alle zugewiesenen Eigenschaften des typs $type entfernen wollen, dieser Schritt kann nicht rückgangig gemacht werden.  .",
  tags_removed_from_users:
    "Alle zugewiesenen Eigenschaften des Typs $type wurden entfernt.",
  add_mandatory_user_property:
    "Bitte wählen sie folgende Merkmale für ihre Tätigkeit aus:",

  upload_certificate: "Zertifikat hochladen",
  delete_certificate: "Zertifikat löschen",
  confirm_delete_certificate:
    "Sind sie sicher, dass sie das Zertifikat $certificateName löschen wollen?",

  certificateName: "Zertifikat Name",
  issuedFrom: "Ausgestellt durch",
  ectsPoints: "ECTS-Punkte",
  ects: "ECTS",

  completedInPercent: "Erfuellt in Prozent",

  issuedAt: "Ausgestellt am",
  receivedAt: "Erhalten am",
  validUntil: "Gültig bis",
  unlimitedValidity: "Unbegrenzte Gültigkeit",

  uploadedCertificates: "Zertifikate",

  drag_drop_steps:
    "Verwenden sie Drag and Drop um elemente aus der linken Spalte einzufuegen.",
  add_lesson: "Lektion hinzufügen",

  minCompletion: "Prozentzahl an Punkten um die Lektion zu bestehen",
  randomStepOrder: "Zufällige Anordnung der einzelnen Schritte.",
  fixedViewExecutionOrder: "Zufällige Anordnung der einzelnen Ansichten.",
  allowBackNavigation: "Ruecknavigation erlauben",
  maxTimeForCompletions:
    "Maximale Zeit um die Lektion abzuschliessen in Minuten",
  maxRetries: "Maximale Anzahl der Wiederholungen",
  maxNumberOfCompletions:
    "Maximale Anzahl der Wiederholungen um die Punktezahl zu verbessern",

  quiz: "Quiz",
  text: "Text",
  multimedia: "Video",
  pdf: "Pdf",
  inputText: "Texteingabe",
  inputDate: "Datumseingabe",

  quiz_description:
    "Stellen sie dem Benutzer eine Frage und lassen sie ihn eine von mehrerern Antwortmoglichkeiten wählen",
  text_description:
    "Zeigen sie dem Benutzer einen Text und lassen sie ihn optional den Inhalt des Textes bestätigen.",
  multimedia_description:
    "Zeigen sie dem Benutzer ein Video und lassen sie ihn optional den Inhalt des Videos bestätigen.",
  pdf_description:
    "Zeigen sie dem Benutzer eine PDF und lassen sie ihn optional den Inhalt bestätigen.",
  inputText_description: "Lassen sie den Benutzer einen Text eingeben.",
  inputDate_description: "Lassen sie den Benutzer ein Datum eingeben.",

  lessons: "Lektionen",
  new_lesson: "Neue Lektion",

  settings: "Einstellungen",
  requiresCompletion:
    "Der Schritt muss erfuellt werden um den Kurs weiter zu bearbeiten",
  requiresInteraction: "Der Benutzer muss diesen Schritt bestätigen",
  isEditable: "Der Benutzer kann diesen Schritt nachträglich bearbeiten",
  points: "Punkte",
  clickToEdit: "(klicken um zu bearbeiten)",
  choiceSettings: "Antwort einstellungen",
  choice: "Antwort",
  choiceText: "Antwort $index text",
  choiceExplanation: "Antwort $index Erklärung",
  choiceIsCorrect: "Richtige Antwort",
  addChoice: "Antwort hinzufuegen",
  deleteChoice: "Antwort löschen",
  uploadVideoFile: "Video datei hochladen",
  discardChanges: "Änderungen verwerfen",
  addInputLabel: "(label hinzufuegen)",
  inputType: "Eingabeart",
  week: "Woche",
  month: "Monat",
  year: "Jahr",
  label: "Label",
  minDate: "Minimales Datum",
  maxDate: "Maximales Datum",
  oneLine: "Einzeilig",
  rows: "Zeilen",
  multipleLine: "Mehrzeilig",
  minLength: "Minimale Länge",
  maxLength: "Maximale Länge",
  showWordLimit: "Maximale Zeichenanzahl anzeigen",
  views: "Ansichten",

  lessonFailedNoMoreRetries:
    "Sie haben nach $retries Versuchen den Kurs nicht bestanden.",
  lessonFailedMoreRetries:
    "Sie haben noch $retries Versuche, um diese Lektion und den Kurs zu bestehen.",
  lessonFailedOneMoreRetries:
    "Sie haben noch einen Versuch, um diese Lektion und den Kurs zu bestehen.",
  registrationKeyAlphanumeric:
    "Registrierungsschlüssel (alphanumerisch, min. 3 Zeichen)",
  registrationKeyMinimumLength:
    "Der Registrierungsschlüssel muss mindestens {length} Zeichen lang sein.",
  chooseAnAccountToContinue: "Wahlen sie ein Konto aus um fortzufahren.",

  help: "Hilfe",
  alreadyRegistered: "Bereits registriert",
  additionalDetails: "Zusätzliche Angaben",
  additionalDetailsSave: "Bestätigen",
  upload: "Upload",
  uploadVideo: "Video uploaden",
  uploadPdf: "Pdf uploaden",
  step: "Schritt",
  flexView: "Ansicht",
  flexViews: "Ansichten",
  lesson: "Lektionen",
  dragInThisAreaToDelete:
    "Ziehen sie elemente in diesen Bereich um diese zu löschen",
  editor: "Editor",
  baseLanguage: "Ausgangssprache",
  translation: "Übersetzung",
  editCourse: "Kurs bearbeiten",
  editTranslation: "Übersetzung bearbeiten",

  pointTracking: "Punkte anzeigen",
  repeatable: "Wiederholbar",
  completionShowScore: "Gesamtpunktzahl anzeigen",
  dragAndDropCertificate:
    "Ziehen Sie PDF-Datei per Drag & Drop oder klicken Sie zum Hinzufügen",
  fileName: "Dateiname",
  fileSize: "Dateigösse",
  userProfileV2: "Nutzerprofil v2",
  certification: "Zertifikate",
  logs: "Logs",
  issuedBy: "Ausgestellt durch",
  issuedOn: "Ausgestellt am",
  fulfilledInPercent: "Erfült im Prozent",
  plusNewTag: "+ Neue Tag",
  orSeparator: "Oder",
  contentManagerPicker: "Inhaltsmanager",
  saveChangesTitle: "Änderungen speichern",
  saveChangesBody:
    "Sie haben nicht gespeicherte Änderungen.\nSollen diese gespeichert oder verworfen werden?",
  flexLessonLocked:
    "Der Benutzer muss diese Lektion abschliessen um fortzufahren.",
  flexSubset:
    "Anzahl von Ansichten aus dieser Lektion die der user bearbeiten muss.",

  curatorName: "Name des Kurators",
  curatorCompany: "Unternehmen des Kurators",
  curatorDescription: "Beschreibung des Kurators",
  courseCompletedTitle: "Kurs abgeschlossen Titel",
  courseCompletedSubtitle: "Kurs abgeschlossen Untertitel",
  courseCompletedNote: "Kurs abgeschlossen Text",
  completionShowScore: "Punkte nach Kursabschluss anzeigen",
  failedCourseInformation: "Textanzeige bei nicht-bestehen des Kurses",
  pinPage: "Ansicht immer am Anfang oder Ende einer Lektion anzeigen",
  start: "Anfang",
  end: "Ende",
  courseDuration: "Dauer des Kurses in Minuten",
  randomizeAnswers: "Antworten durchmischen",
  closeEditor: "Editor schließen",
  courseNotEditable:
    "Dieser Kurs ist bereits in Benutzung und kann deshalb nicht editiert werden.",
  flex: "Flex",
  copied: "In die Zwischenablage kopiert",
  covidCertificates: "Impfzertifikate",
  covidCertificatesDetail: "Verwalten von Impf- und Testzertifikaten",
  covidInstructions: "COVID-19-relevante Anweisungen",
  covid: "COVID-19",
  addCertificate: "Zertifikat hinzufügen",
  scanQrCode: "QR-Code Einscannen",
  scanQrCodeDescription:
    "Folgende QR-Codes können eingescannt oder hochgeladen werden: Impfzertifikate, PCR- und Antigen-Testzertifikate, Genesungszertifikate",
  openFile: "Datei öffnen",
  openImage: "Bilddatei öffnen",
  validFrom: "Gueltig seit $date",
  validFor: "Gueltig am $date",
  vaccinationCertificatesValid:
    "Ihre Zertifikate entsprechen den vorgegebenen Regeln",
  vaccinationCertificatesInvalid:
    "Ihre Zertifikate entsprechen nicht den vorgegebenen Regeln",
  certificateInvalid: "Dieses Zertifikat ist ungültig",
  certificateExpired: "Dieses Zertifikat ist abgelaufen",
  updatedOn: "Aktualisiert am $date",
  uploadinCertificate: "Zertifikat hochladen",
  certificateUploadError: "Fehler beim hochladen des Zertifikats",
  certificateNameMismatch:
    "Das Zertifikat wurde auf einen anderen Namen ausgestellt.",
  certificateBirthdayMismatch:
    "Das Zertifikat wurde auf ein anderes Geburtsdatum ausgestellt.",
  certificateExists: "Das Zertifikat existiert bereits.",
  certificateUploaded: "Zertifikat erfolgreich hochgeladen",
  instructions: "Anweisungen",
  accessRules: "Zutrittsregeln",
  selectAccessRule: "Zutrittsregel auswählen",
  qrCode: "QR-Code",
  certificateDeleted: "Zertifikat entfernt",
  deleteCertificateConfirmation:
    "Sind Sie sicher, dass Sie dieses Zertifikat entfernen möchten?",
  event: "Event",

  instructions: "Anweisungen",
  isOnlineEvent: "Online Veranstaltung",
  eventLink: "Event link",
  eventLocation: "Ort",
  selfRegistration: "Selbstregistrierung",
  attendanceConfirmation: "Anwesenheit",
  passedConfirmation: "Bestanden",
  mark: "Benotet",
  trainer: "Trainer",
  attendanceAndGrades: "Anwesenheit und Benotung",
  onlyIfMandatoryCourse: "Bei Pflichtzuweisung",
  allUsersInAccount: "Alle Nutzer des Accounts",
  dateManagement: "Terminverwaltung",
  dateManagementDetail: "Verwaltung der Termine und Teilnahmeergebnisse",
  courseFilter: "Kursfilter",

  fromTo: "Von - bis",
  capacity: "Kapazität",
  unlimitedCapacity: "Unbegrenzte Kapazität",
  invalidatePreregistration:
    "Verfall der Voranmeldungen in Stunden vor der Veranstaltung",
  allowPreregistration: "Voranmeldungen zulassen",
  newDate: "Neuer Termin",
  editDate: "Termin bearbeiten",
  preenrollments: "Voranmeldungen",
  enrollments: "Anmeldungen",
  preregistrationDate: "Voranmeldung $date",
  registered: "Angemeldet",
  notRegistered: "Nicht angemeldet",
  registeredAndNotRegistered: "Angemeldet / Nicht angemeldet",
  selectCourseToContinue: "Kurs auswählen um fort zu fahren",
  changesSavedSuccessfully: "Änderungen erfolgreich gespeichert",
  cancelRegistrationTitle: "Abmeldung",
  cancelRegistrationBody:
    "Möchten sie wirklich $name von dem folgenden Termin abmelden?",
  dates: "Termine",
  unregister: "Abmelden",
  changeRegistrationTitle: "Ummeldung",
  currentRegistration: "$name ist bereits für folgenden Termin angemeldet",
  changeRegistrationBody:
    "Möchten Sie die bestehende Anmeldung aufheben und einen neue für folgenden Termin erstellen?",
  preregistrationAvailableRegistrations: "Voranmeldungen / Freie Plätze",
  seatsLeft: "Freie Plätze",
  registrationTitle: "Anmeldung",
  registrationBody:
    "Durch die Anmeldung zum folgenden Termin wird der Kurs $course dem Nutzer als Pflichtkurs zugeordnet.",
  register: "Anmelden",
  myDates: "Meine Termine",
  dateDetails: "Termindetails",
  attendees: "Teilnehmer",
  fractionOfTotal: "$fraction von $total",
  registrations: "Registrierungen",
  link: "Link",
  notPassed: "Nicht bestanden",
  passed: "Bestanden",
  attended: "Teilgenommen",
  notAttended: "Nicht teilgenommen",
  youAreRegisteredFor: "Sie sind für folgenden Termin angemeldet:",
  unlimitedSeats: "Keine Platzbeschränkung",
  otherDates: "Weitere Termine",
  managerWillAssignDate:
    "Sie werden von ihrem Manager für einen Termin angemeldet.",

  courseCompletionsPerUser: "Kursabschlüsse pro Nutzer",
  courseAssignmentsPerUser: "Kurszuweisungen und -abschlüsse",
  userState: "Nutzerstatus",
  accountStatistics: "Konto Statistiken",
  reportExcelDownloadBtn: "Excel report downloaden",
  reportDisplayBtn: "Anzeigen",
  covidReport: "Covid report",
  reportExcelCourseCompletion: "Kurszuweisungen und Kursabschlüsse",
  reportExcelPracticalTests: "Events report",
  listOfAccomplishedCourses:
    "Liste von Pflichtzuweisungen und optionalen Kursabschlüssen pro Nutzer",
  listOfAssignedCourses:
    "Liste der obligatorischen und optionalen Kurszuweisungen, Abschlüsse und Sperren pro Benutzer",
  reportUserState:
    "Liste aktueller Zugangsberechtigungen und Kursabschlussinformationen pro Nutzer",
  descriptionCovidReport: "Liste aller COVID Zertifikate",
  descriptionCourseAssignmentsAndCompletion:
    "Liste aller Kurszuweisungen und aktueller Kursabschlussstatus",
  descriptionEventReport: "Liste der Registrierungen und Teilnahmen an Events",
  descriptionAccountStatistics: "Kontospezifische KPI's und Kursstatistiken",
  userRoleAssignments: "Nutzer Rollen",
  listOfRolesPerUser: "Liste der Rollen aller Nutzer per Account",

  covidInstructionsLabel: "Anweisungen in der Systemsprache $lang.",
  cameraNotAllowedError:
    "Die Verwendung der Kamera wurde abgelehnt. Laden sie die Seite neu oder ändern sie den Kamerazugriff in den Browser-Einstellungen.",
  cameraNotFoundError:
    "Es wurde keine Kamera gefunden. Bitte verwenden sie den upload stattdessen.",
  cameraNotReadableError:
    "Es konnte nicht auf die Kamera zugegriffen werden. Stellen sie sicher, dass kein anderes Programm die Kamera verwendet.",
  cameraNotAvailable:
    "Ihr browser unterstützt keinen Kamerazugriff. Bitte verwenden sie den upload stattdessen.",
  qrCodeUploadError:
    "Der QR Code konnte nicht gelesen werden. Stellen sie sicher dass nur ein QR Code auf dem Bild vorhanden ist, und erhöhen sie gegebenenfalls die Auflösung.",
  confirmCovidRulesManuallyTitle: "COVID-19 - Visuelle Statuskontrolle",
  confirmCovidRulesBody:
    "Hiermit bestätige ich, dass die von $user gezeigten Nachweise der aktuellen COVID-19 Regelung entsprechen und für den heutigen Tag gültig sind.",

  activateApis: "API-Bereich aktivieren",
  allowApiManagement: "API-Verwaltung erlauben",
  allowedApis: "Freigeschaltete Apis",
  volume: "Volume in req/day",
  throttle: "Throttle in req/min",

  systemLogs: "System Logs",
  systemConfigurationDetail: "Systemeinstellungen konfigurieren",
  systemConfiguration: "Systemeinstellungen",

  apis: "APIs",
  apisDetail: "Verwalten der API-Schlüssel",

  keys: "Schlüssel",
  key: "Schlüssel",
  keyName: "Name",
  keyPurpose: "Verwendungszweck",
  createApiKey: "Schlüssel hinzufügen",
  keyCopiedToClipboard: "Der Schlüssel wurde in die Zwischenablage kopiert.",
  deleteKey: "Schlüssel löschen",
  deleteKeyMessage:
    "Sind sie sicher, dass sie den Schlüssel '$name' für '$purpose' löschen wollen?",
  changeKey: "Schlüssel bearbeiten",
  keyCreatedSuccessfully: "Der Api Schlüssel wurde hinzugefügt",
  keyUpdatedSuccessfully: "Die Änderungen wurden erfolgreich gespeichert",
  api: "Api",
  addApi: "Api hinzufügen",
  editApi: "Api bearbeiten",
  example: "Beispiel",
  apiDetails: "Api details",
  flex: "Flex",
  notAvailable: "Nicht verfügbar",
  method: "Methode",
  requestBody: "Anfrage",
  responses: "Antworten",
  parameters: "Parameter",
  manualCheck: "Nachweiskontrolle",
  qGateTitle: "Obligatorische Qualitätskontrolle der Nutzerdaten",
  qGateDescription:
    "Durch das Aktivieren von Q-Gate fuer die Nutzerdaten wird bei der Nutzeranmeldung zum Zugang eine Bestaetigung abgefragt, dass die Nutzerdaten erforderlicher Qualität entsprechen und vollstaendig sind. Ggf werden noch weitere Daten ueber den Nutzer abgefragt.",
  subcontractorTitle: "Sichtbarer Subkontraktor Tab",
  subcontractorDescription:
    "Aktivieren von Subkontraktor Tab ermoeglicht die Pflege der Subkontraktoren pro Account und ihre Nutzung als Referenz fuer Account Nutzer.",
  subcontractor: "Subkontraktor",
  subcontractors: "Subkontraktoren",
  addSubcontractor: "Subkontraktor hinzufügen",
  editSubcontractor: "Subkontraktor bearbeiten",
  street: "Strasse",
  deleteSubcontractor: "Subkontraktor löschen",
  deleteSubcontractorBody: "Firma: $name",
  deleteSubcontractorHasReference:
    "Subkontraktor wurde bereits referenziert - Löschen nicht möglich",
  accessRegistration: "Anmeldung der Mitarbeiterpraesenz",
  accessFrom: "Zugang von",
  accessTo: "vorraussichtlich bis",
  birthplace: "Geburtsort",
  nationality: "Nationalität",
  car: "Parkerlaubnis",
  carPlate: "KFZ-Kennzeichen",
  socialSecurity: "Sozialversicherungsnr.",
  workPermit: "Arbeitserlaubnis bis",
  confirmData:
    "Hiermit bestätige ich die Richtigkeit und Vollständingkeit der Nutzerdaten",
  accessRegistration: "Zugangsanmeldung",
  registeredOn: "Registriert am",
  registeredBy: "Registriert von",
  isOnline: "Online",
  coursePreRegistrationUsedFrom:
    'Gesamtanzahl der Voranmeldungen fuer den Kurs "{courseName}" - {preRegTotal} davon verfallen demnaechst {preRegAboutToCancel}',
  notEnoughCapacityReload:
    "Nicht genuegend freie Kapazität. Die aktuellen Kapazitäten werden neu geladen",
  editUserData: "Nutzerdaten bearbeiten",
  dateSpecificInstructions: "Terminspezifische Anweisungen",
  preRegistrationClosed: "Voranmeldungen sind verfallen",
  preRegistrationNotificationsTitle:
    "E-Mails ueber verfallende Vorregistrierungen versenden",
  preRegistrationNotificationsDescription:
    "Account owner werden ueber in den naechsten Tagen verfallende Vorregistrierungen informiert",
  automaticAssignments: "Automatisierung",
  info: "Info",
  automated: "Automatisch",
  assignAutomaticallyTo: "Automatisch zuweisen",
  allUsers: "Alle Nutzer",
  usersWithAssignedProperty: "Nutzer mit Eigenschaft",
  assignmentType: "Zuweisungstyp",
  optionally: "Optional",
  mandatory: "Pflicht",
  exactDate: "Exaktes Datum",
  relativeDateOffsetDays: "Relativ zum aktuellen Datum (in Tagen)",
  emailNotification: "E-Mail Benachrichtigung",
  selectPropertyMandatory: "Eigenschaft auswählen",
  setAllSelected: "Für Auswahl setzen",
  nSelected: "{count} Ausgewählt",
  publishedUnpublished: "Veröffentlicht/Unveröffentlicht",
  published: "Veröffentlicht",
  unpublished: "Unveröffentlicht",
  noneInfoAutomated: "Keine/Info/Automatisch",
  scope: "Bereich",
  tagged: "Getaggt",
  notification: "Benachrichtigung",
  selectAll: "Alle auswählen",
  passwordResetLinkInactive:
    "Der verwendete Passwort Reset Link ist nicht mehr verfügbar.",
  requestNewLink: "Neuen Link anfordern",
  testQuestionnaire: "Fragebögen",
  newQuestionnaire: "Neuer Fragebogen",
  newTestQuestionnaire: "Neuer Fragebogen",
  testQuestionnaireDetail: "Verwaltung der Fragebögen fuer Events",
  addQuestion: "Frage hinzufügen",
  editTestQuestionnaire: "Fragebogen bearbeiten",

  requiredPoints: "Benötigte Punkte",
  questions: "Fragen",
  question: "Frage",
  answer: "Antwort",
  responseDocumentation: "Antwort-Dokumentation",
  ko: "Ko",
  failedPassed: "Richtig/Falsch",
  failedPassedComment: "Richtig/Falsch + Kommentar",
  assignedCourses: "Zugewiesene Kurse",
  noCoursesAssigned: "Keine Kurse zugewiesen",
  questionnaire: "Fragebogen",
  questionnaireLocked: "Fragebogen gesperrt",
  born: "geboren am",
  examinee: "Prüfling",
  totalResult: "Gesamtresultat",
  comment: "Kommentar",
  done: "Fertig",
  pointsRequired: "Benötigte Punkte",
  correct: "Richtig",
  wrong: "Falsch",
  locked: "Gesperrt",
  locks: "Sperren",
  lockedAtCest: "Gesperrt am (CEST)",
  unlockCourse: "Kurssperre aufheben",
  unlockCourseMessage:
    "Moechten sie wirklich die Sperre für den Kurs {0} des Nutzers {1} aufheben?",
  allowedNumberOfAttempts: "Erlaubte Anzahl der Versuche",
  noDates: "Es gibt derzeit keine Termine",
  testResults: "Terstergebnisse",
  pointScore: "{points} Punkte",
  markScore: "Note: {mark}",
  registrationLocked: "Registrierung geschlossen",
  registrationLockedMessage:
    "Die Ergebnisse des Events werden aktuell bearbeitet. Eine Änderung der Registrierung ist nach der Bewertung möglich.",
  properties: "Eigenschaften",
  propertiesDetail: "Verwaltung von Nutzer- und Kurseigenschaften",
  types: "Typen",
  addTagType: "Typ hinzufügen",
  editType: "Typ bearbeiten",
  create: "Erstellen",
  deleteTagType: "Tag typ entfernen",
  confirmDeleteTagTypeName:
    "Bitte bestätigen sie, dass der typ {name} enfernt werden soll?",
  relatedProperties: "Zugehörige Eigenschaften",
  addTagValue: "Eigenschaft hinzufügen",
  deleteTagValue: "Tag löschen",
  confirmDeleteTagValueName:
    "Bitte bestätigen sie, dass die Eigenschaft {name} entfernt werden soll?",
  editTagValue: "Eigenschaft bearbeiten",
  purpose: "Verwendung",
  uploadLabel: "Label auswählen",
  tagLabelSizeError:
    "Das ausgewählte Bild ist zu groß. Es werden nur Bilder mit maximal 20Kb unterstützt.",
  fileSizeError:
    "Diese Datei ist zu groß, aktuell werden maximal {sizeMb} Mb unterstützt",
  passed: "Bestanden",
  failed: "Nicht bestanden",
  emergencyContact: "Notfallkontakt",
  emergencyContactPhone: "Telefonnummer des Notfallkontakts",
  userHasNoAccount: "Der Nutzer hat keinen Account",
  driveInPermit: "Parkerlaubnis",
  downloadRegistrationForm: "Anmeldebogen",
  accessStartDate: "Zugang startet am",
  today: "Heute",
  tomorrow: "Morgen",
  dayAfterTomorrow: "Übermorgen",
  tests: "Tests",
  createTest: "Test erstellen",
  planned: "Geplant",
  myTests: "Meine Tests",
  houseNumber: "Hausnr.",
  actionResult: "Aktion",
  actionResultKpi: "KPI",
  missedAfter: "Gilt als verpasst nach",
  testPerson: "Test person",
  createAndDocument: "Erstellen & Dokumentieren",
  eob: "EOB",
  testDate: "Testdatum",
  test: "Test",
  testManagement: "Test management",
  testManagementDetail: "Verwalten von Testtypen",
  addTestType: "Testtyp hinzufügen",
  testType: "Testtyp",
  testTeam: "Testteam",
  storage: "Aufbewahrung",
  validity: "Gültigkeit",
  editTestType: "Testtyp bearbeiten",
  testValidityInDays: "Testgültigkeit (Tage)",
  retentionPeriod: "Aufbewahrungsdauer (Tage)",
  createTestType: "Testtyp erstellen",
  authorizedTesters: "Authorisierte Tester",
  testAlertReceivers:
    "Alarmempfänger bei positivem Test (E-mail Adressen, Komma separiert)",
  documentTestResult: "Testresultat dokumentieren",
  confirmCorrectnessTestResultData: `Bitte überprüfen sie die Richtigkeit der Angaben. 
  Nach der Bestätigung können die Daten nicht mehr angepasst werden.`,
  testCreated: "Test erstellt",
  testResultDocumented: "Testresultat wurde gespeichert",
  result: "Ergebnis",
  expiresIn: "Läuft ab in",
  positive: "Positiv",
  negative: "Negativ",
  testResult: "Testresultat",
  open: "Offen",
  testReportHeader: "Test Termine und Ergebnisse",
  testReportBody: "Übersicht anstehender Tests sowie der Testresultate.",
  display: "Anzeigen",
  testReportEnterData:
    "Bitte wählen sie ein Start- und Enddatum sowie einen Testtyp.",
  startDate: "Startdatum",
  endDate: "Enddatum",
  searchUser: "Nutzer suchen",
  completedAt: "Abgeschlossen am",
  enterAtLeastTwoLetters: "Mindestens 2 Buchstaben müssen eingegeben werden",
  testsLimitExceeded:
    "Die Anfrage hat zu viele Ergebnisse. Die Darstellung ist auf 2000 Ergebnisse begrenzt.",
  missed: "Verpasst",
  scheduled: "Geplant",
  reportExcelDownloadBtn: "Excel Report Download",
  saveAndApply: "Speichern & Anwenden",
  errorApplyingRule:
    "Fehler beim anwenden der Regel. Versuchen sie es in ein paar Minuten nochmals.",
  triggerAllSelected:
    "Automatisierung fuer bestehende User der ausgewählten Konten anwenden",
  assignmentRuleApplied: "Automatisierungsregel wurde angewendet.",
  selectAccountWithAutomationToContinue:
    "Wählen sie mindestens ein Konto mit bestehender Automatisierungsregel um diese anzuwenden.",
  automationCenter: "Automatisierungen",
  automationCenterDetail: "Erstellen und Verwalten automatisierter Regeln.",
  newRule: "Regel hinzufügen",
  topic: "Anwendungsgebiet",
  newTestCenterAutomationRule: "Neue Automatisierungsregel",
  global: "Global",
  accountSpecific: "Kontospezifisch",
  selectTemplate: "Vorlage auswählen",
  lastRun: "Letzte Ausführung",
  rule: "Regel",
  reportIsBeingCreated: "Der Bericht wird erstellt.",
  miscellaneous: "Sonstiges",
  selectedAccounts: "Ausgewählte Konten",
  mo: "Mo",
  tu: "Di",
  we: "Mi",
  th: "Do",
  fr: "Fr",
  sa: "Sa",
  su: "So",
  selectTopic: "Anwendungsgebiet auswählen",
  deleteAutomationRule: "Automatisierung löschen",
  deleteAutomationRuleConfimration:
    "Sind sie sicher dass sie die Regel {rule} löschen wollen?",
  editAutomation: "Automatisierung bearbeiten",
  triggerAt: "Ausführen am",
  addCoverPhoto: "Coverbild hinzufügen",
  triggeredBy: "Ausgeführt von",
  criticality: "Kritikalität",
  logDetails: "Log Details",
  confirmDelete: "Löschen bestätigen",
  confirmDeleteTest:
    "Sind sie sicher dass sie den test {type} am {date} für {name} löschen wollen?",
  lastHour: "Letzte Stunde",
  last24Hours: "Letzte 24 Stunden",
  last3Days: "Letzte 3 Tage",
  lastWeek: "Letzte Woche",
  accountsImported: "Konten hinzugefügt",
  importMoreAccounts: "Weitere Konten hinzufügen",
  backToOrganization: "Zurück zur Organisationsstruktur",
  retention: "Aufbewahrung",
  maximumAllowed: "Erlaubtes Maximum liegt bei {maximum}.",
  completionValidity: "Abschlussgültigkeit",
  completionValidityinCertificate: "Abschlussgültigkeit im Zertifikat",
  useTranslationsToEdit:
    "Bitte verwenden sie zum editieren den Übersetzungseditor",
  hardwareRestrictions: "Gerätebeschränkungen",
  hardwareRestriction: "Gerätebeschränkung",
  hardwareRestrictionsDetail:
    "Verwaltung von Geräten auf welchen Kurse ausgeführt werden können.",
  installed: "Installiert",
  notInstalled: "Nicht installiert",
  installKeyOnThisDevice: "Schlüssel auf diesem Gerät installieren",
  removeKeyOnThisDevice: "Schlüssel von diesem Gerät entfernen",
  add: "Hinzufügen",
  createKey: "Schlüssel hinzufügen",
  editKey: "Schlüssel bearbeiten",
  accessCourseAccount: "Zugang (Kurs: Konten)",
  deselectAll: "Alle abwählen",
  hardwareRestrictionKeyRequiredHeader: "Beschränkter Kurs",
  hardwareRestrictionKeyRequiredBody:
    "Dieser Kurs kann nur auf bestimmten Computern ausgeführt werden. Kontaktieren sie ihren Vorgesetzten, falls dieser Kurs auf diesem Computer ausgeführt werden sollte.",
  published_courses_for_account: "Veröffentlichte Kurse",
  deleteSession: "Sessions entfernen",
  deleteSessionConfirmation:
    "Sind Sie sicher dass Sie die Session entfernen wollen?",
  startedCourses: "Angefangene Kurse",
  startedOn: "Angefangen am",
  courseSessionDeleted: "Session wurde entfernt!",
  noCourses: "Keine Kurse",
  startedBy: "Gestartet von",
  noUsers: "Keine Nutzer",
  deleteAllSessions: "Alle Sessions entfernen",
  deleteAllSessionsConfirmation:
    "Sind Sie sicher dass Sie alle Sessions entfernen wollen?",
  uploading: "Lade hoch",
  incomplete: "Unvollständig",
  expired: "Abgelaufen",
  due: "Fällig",
  loginRedirects: "Anmeldungsweiterleitungen",
  createLoginRedirect: "Anmeldungsweiterleitung erstellen",
  redirectFromLogin: "Direkt zur Registrierung weiterleiten ohne Anmeldung",
  installLoginRedirectOnThisDevice:
    "Anmeldeumleitung auf diesem Gerät installieren",
  redirects: "Weiterleitungen",
  removeLoginRedirectOnThisDevice:
    "Anmeldeumleitung von diesem Gerät entfernen",
  localLoginKeyNoLongerExists:
    "Die lokal installierte Anmeldeumleitung existiert nicht mehr und wurde entfernt",
  localLoginKeyUpdated:
    "Die lokal installierte Anmeldeumleitung wurde mit den aktuellen Daten erneuert",
  systemConfigurationError:
    "Das System ist falsch konfiguriert, kontatktieren sie den Systemadministrator. ",
  addProperty: "Eigenschaft hinzufügen",
  confirmDeleteName: "Sind sie sich sicher, dass sie {name} löschen wollen?",
  default: "Standard",
  selectView: "Ansicht wählen",
  addSection: "Abschnitt hinzufügen",
  editSection: "Abschnitt bearbeiten",
  notTested: "Nicht geprüft",
  confirmDeleteQuestionnaireSection:
    "Bitte bestätigen sie, dass sie den Abschnitt {0} löschen wollen. Alle Fragen in diesem Abschnitt werden in den Anfangsabschnitt verschoben.",
  nOfMQuestionsEdited: "{n} von {m} Fragen bearbeitet",

  recogniseCourseForName: "Kurs für {name} anerkennen",
  courseRecognitions: "An-/Aberkannte Kurse",
  reason: "Grund",
  recognise: "Anerkennen",
  revoked: "Aberkannt",
  recognised: "Anerkannt",
  selectedCourseNotPublishedForUser:
    "Der ausgewählte Kurs ist für den Nutzer nicht veröffentlicht",
  confirmCourseRecogntionForUser:
    "Bitte bestätigen sie, dass sie {course} für {user} anerkennen wollen.",
  confirmRevokeCourseForUser:
    "Bitte bestätigen sie, dass sie {course} für {user} aberkennen wollen.",
  revocation: "Aberkennung",
  revoke: "Aberkennen",
  fieldIsRequired: "{field} muss ausgefüllt werden",
  fieldsAreMissing: "Die folgenden Felder müssen ausgefüllt werden: {fields}",
  automatedLogout: "Abmeldung nach Kursabschluss",
  logout: "Abmelden",
  continue: "Fortfahren",
  courseFinishedDoYouWantToLogout:
    "Der Kurs wurde abgeschlossen. Möchten sie fortfahren oder sich abmelden? Sie werden automatisch in {seconds} abgemeldet.",
  stateless: "Staatenlos",
  file: "Datei",
  monitoring: "Monitoring",
  fraudDetection: "Betrugserkennung",
  fraudDetectionDetail:
    "Automatische erkennung von verdächtigen Aktivitäten und Anomalien",
  falsePositive: "False positive",
  inProcess: "Im Prozess",
  severity: "Schweregrad",
  dataQuality: "Datenqualität",
  dataQualityDetail: "Qualitätsmonitoring der im System eingetragenen Daten",
  lastNDays: "Letzte {n} Tage",
  createPreconfigurationLink: "Konfigurationslink erstellen",
  createPreconfigurationLinkDescription:
    "Der Konfigurationslink speichert die aktuellen Gerätebeschränkungen und Anmeldeumleitungen auf jedem Gerät auf dem er ausgeführt wird.",
};
