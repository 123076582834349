import enLocale from "element-plus/dist/locale/en";

export default {
  ...enLocale,
  termsAccept: "I accept the {0}.",
  tos: "Terms and Conditions",
  privacyAccept: "I accept the {0}.",
  privacyPolicy: "Privacy Policy",
  resetPassword: "Reset password",
  usernamePasswordRequired: "Please enter a username and a password.",
  passwordTooShort: "The password has to consist of at least 8 characters.",
  wrongCredentials: "The provided credentials did not match any account.",
  accountStructureItem: "Department",
  addRole: "Add role",
  allUserRelatedDeleteText:
    "All user-related data of will hereby be irreversible deleted.",
  assignRoleModals: "Assign Role modals",
  canBeDownloadedText: "can be downloaded under completed",
  changePassword: "Change password",
  changingPasswordFor: "Changing password for the user",
  confirmDeleteText: "Please confirm by typing in the username:",
  confirmDeteleBtnText: "I understand, delete this user",
  confirmPassword: "Confirm password",
  createAccount: "Register User",
  createUser: "Create user",
  currentPassword: "Current password",
  customId: "Custom ID",
  dataPrivacyHeader: "Data privacy",
  dataPrivacyText: "Section text (content will follow)",
  dataPrivacyTitle: "Section title",
  dataRelatedToMe: "data related to me",
  dataRelatedToMeTitle: "Data related to me",
  deleteText: "Are you sure you want to delete the",
  downloadDataRelatedToMe: "download data related to me as .txt",
  fileWithUserInformation: "File with user information",
  forgotPassword: "Forgot your password?",
  forgotPasswordText:
    "Please, enter your email. We'll email instructions on how to reset your password.",
  importUsers: "Import users",
  newHere: "New here?",
  newPassowrd: "New password",
  newPasswordShort: "New password too short",
  password: "Password",
  passwordCantBeEmpty: "Password can't be empty",
  passwordsNotMatch: "Passwords don't match",
  pickUser: "Pick a user",
  pleaseFillUpAllFields: "Please fill up all fields",
  pleaseTypeNewPassword: "Please type your new password",
  policyAcceptance: "Policy adoption",
  policyAcceptanceSave: "accept",
  acceptPolicies: "Please, accept the policies",
  profile: "Profile",
  role: "Role",
  roleExpiresOn: "Role expires on",
  saveDataAboutMe: "saved data about me",
  signIn: "Sign In",
  termsConditionsHeader: "Terms & Conditions",
  termsConditionsText: "Section text (content will follow)",
  termsConditionsTitle: "Section title",
  typeInOne: "Type in one for all",
  firstname: "First Name",
  lastname: "Surname",
  username: "Username",
  usernameMail: "Username or email address",
  userNotes: "Notes",
  userProfile: "User profile",
  userStatus: "User status",
  wrongCurrentPassword: "Wrong current password",

  minimumLength: "minimum length 8",
  atLeastOneBigAndSmall: "at least 1 small and 1 big letter",
  passwordNotEqualUsernameAndEmail:
    "password not equal to username and email address",

  registrationKey: "Registration Key",
  registration: "Registration",
  notAnEmailAdress: "Please enter a valid E-Mail address.",

  company: "Company",
  companyHint: "Company",
  birthday: "Birthday",
  birthdayHint: "Birthday",
  birthyear: "Year of birth",
  accountName: "Account name",
  accountType: "Account type",
  accountAddress: "Account address",
  logo: "Logo",
  address: "Address",
  contry: "Country",
  lessThan: "less than 1mb",
  defaultLanguage: "Default language",
  accountStatus: "Account status",
  accountNotes: "Account notes",
  createAccount: "Register User",
  fileToImport: "Account notes",
  importStructure: "Import structure",
  updateAccount: "Update account",
  addRootAccount: "Add root level account",
  dateRange: "Date range",
  usersPerMonth: "Users per month",
  usersPerMonthGlobal: "Users per month, globally",

  usersAdded: "Users added",
  usersDeleted: "Users deleted",
  usersTotal: "Users total",
  usersBilling: "Users billing",
  city: "City",
  users: "Users",
  coursesAvailable: "Available Courses",
  started: "Started",
  completed: "Completed",
  openMandatoryAssignments: "Open mandatory assignments",
  mandatoryAssignments: "Mandatory assignments",
  isMandatory: "Mandatory",
  assignments: "Assignments",
  dueMandatoryCompletions: "Due mandatory Completions",
  of: "of",
  accountOverview: "Account overview",
  selectAccount: "Select an account",
  noAccountsAvailable: "No accounts available",
  completionRate: "Completion rate",
  averageResult: "Average result",
  courseOverview: "Course overview",
  reportCoursesSearchPlaceholder: "Search a course",
  completedLastWeeks: "Course completions in the last {number} weeks",
  completedLastMonths: "Course completions in the last {number} months",
  executionTime: "Distribution by execution time (min)",
  completionLevel: "Distribution by completion points",
  validTo: "Valid to",
  knowledge: "OVERVIEW",
  toolbox: "TOOLBOX",
  lessonCompleted: "Lesson completed",
  lessonFailed: "Lesson failed",
  repeatLesson: "Please repeat the lesson in order to continue",
  congratulations: "Congratulations",
  courseCompleted: "Course completed",
  downloadCertificate:
    'You can download your certificate under "Certificates" in your profile, or request your certificate via E-Mail',
  trueFalse: "True or False?",
  seconds: "sec",
  noChoiceText: "Too late, what a pity!",
  right: "True!",
  wrong: "False",
  percentCompleted:
    "You have answered $successRate% of all questions correctly.",
  progress: "$current of $total",

  repeat: "Repeat",
  responsesMissing: "Please complete all items before you continue",
  addCourseContent: "Add course content",
  startCourseEditor: "Start course editor",
  copyCourse: "Copy Course",
  courseCopyStarted: "Copy action was started",
  courseCopySuccess: "Course was copied successfully",
  transferCourse: "Transfer course",
  systemUrl: "System-URL",
  addSemicolon: "Add labels separated by a semicolon",
  addToProgram: "Add to program",
  allAccounts: "All accounts",
  certificate: "Certificate",
  certificates: "Certificates",
  completed: "Completed",
  contentIsLoaded: "Content is loaded",
  contentManager: "Content manager",
  courseCompleted: "Course completed",
  courseContent: "Course content",
  courseFormat: "Course format",
  coverPhoto: "Cover Photo",
  EditCoverPhoto: "Edit Cover Photo",
  AddCoverPhoto: "Add Cover Photo",
  additionalDocuments: "Additional Documents",
  editAdditionalDocuments: "Edit Additional Documents",
  addAdditionalDocuments: "Add Additional Documents",
  documents: "Documents",
  courseLables: "Course Labels",
  courseLanguage: "Course language",
  courseNotes: "Course notes",
  courseStructure: "Course structure",
  courseTags: "Course Tags",
  courseTitle: "Course title",
  createCourse: "Create course",
  curator: "CURATOR",
  description: "Description",
  details: "Details",
  dueDate: "Due date",
  duration: "Duration",
  exportStatistics: "Export statistics",
  learningStandard: "Learning standard",
  licenses: "Licenses",
  mandatory: "Mandatory",
  noAssessment: "No assessments to evaluate",
  noCoursesAssigned: "No courses assigned to your program. Please visit",
  noCoursesAvailable: "No courses are currently available",
  noDueDate: "No Due Date",
  overview: "Overview",
  page: "page",
  pickAnAccount: "Pick an account",
  printCertificate: "Print",
  publishCourse: "Publish course",
  publishedToAccount: "Published to Account",
  relatedCourses: "Related courses",
  remove: "Remove",
  removeFromProgram: "Remove from Programm",
  singleAccount: "Single Account",
  startCourse: "Start",
  successMessageOne:
    "Congratulations, you have successfully completed the course ",
  successMessageThree:
    "Now you can download or print certificate of course completion.",
  successMessageTwo: " !",
  tags: "Tags",
  unpublishCourse: "Unpublish course",
  updateCourseContent: "Upload course content",
  updateCourse: "Update course",
  youDidntfinishCourse: "You didn't finish any course.",
  courseVisibleFor: "Course visible for",
  accountStructureItem: "Department",
  courseAssigmentOptions: "Course assignment options",
  assignCourse: "Assign course",
  pickACourse: "Pick a course",

  noDocumentsAvailable: "no documents available",
  nothingRelated: "nothing related",
  nothingWritten: "nothing written",
  noTagsAvailable: "no tags available",
  alreadyGlobal: "The course is already published globally.",
  questionsanswered: "questions",
  completedOn: "Completed on",
  locale: "en-EN",
  quizAuthorizationContent: "Quiz Authorizations",

  courseNotPublished: "The course is currently not published",
  courseNotPublishedForAccount:
    "The course is currently not published for the account $account",
  clickToPublishCourse: "Publish course",
  visible: "Visible",
  userIsNotContentManager:
    "You are not listed as a content manager and thus can't edit the content of this course.",

  assignmentOpen: "Open",
  assignmentStarted: "Started",
  assignmentCompleted: "Completed",
  dueDatePassed: "Due date passed",
  exitCourse: "Exit Course",
  markAsFinished: "Mark as finished",

  assignToAllUsers: "Assign course to all users in the account",
  learningPoints: "Learning Points",
  learningPointsAbbreviation: "LP",
  back: "Back",
  next: "Next",
  courseOverview: "Course Overview",

  system: "System",
  configureLogging: "Configure Logging",
  createAccount: "Create account",
  changeAccount: "Change account",
  deleteAccount: "Delete account",
  importAccountStructure: "Import account structure",
  createUser: "Create user",
  importUser: "Import users",
  changeUser: "Change user",
  deleteUser: "Delete user",
  assignUserRole: "Assign user role",
  resetPassword: "Reset password",

  createCourse: "Create course",
  changeCourse: "Edit course",
  addContent: "Add content",
  changeContent: "Edit content",
  addQuizContent: "Add quiz content",
  changeQuizContent: "Change quiz content",
  deleteCourse: "Delete course",
  deleteContent: "Delete content",
  publishCourse: "Publish course",
  unpublishCourse: "Unpublish course",
  assignCourse: "Assign course",
  unassignCourse: "Unassign course",

  systemMessages: "System messages",

  learningManagement: "Learning Management",
  courseManagement: "Course Management",
  userManagement: "User Management",
  organizationStructure: "Organization structure",
  accountManagement: "Account Management",
  system: "System",

  learningManagementDetail: "Learning plans and course assignments",
  courseManagementDetail: "Course content administration",
  userManagementDetail: "User administration and password reset",
  organizationStructureDetail: "Definition of organization structure",
  accountManagementDetail: "Administration of accounts",
  logsDetail: "System logs and settings",
  noResults: "No Results",
  noData: "No Data Available",

  userCreateSuccess: "User created successfully",
  createOtherUser: "Create another user",
  backToUserOverview: "Back to user overview",
  currentRoles: "Current roles",

  numberOfRoles: "Number of roles",
  numberOfUsers: "Users",
  deleteAccountMessage:
    "Please confirm that you want to delete the account '$account' .",

  showStatistics: "Statistics",
  appendAccount: "Append account to $account.",

  courseAssignments: "Assignments",
  courseAssignmentsForCourse: "Course assignments for $course",
  mandatoryAssignments: "Mandatory assignments",
  dueDateAssignments: "With due date",
  dueDatePassed: "Due date passed",

  userOrAccount: "User or Account",
  expiryDate: "Expiry date",

  deletePublication: "Unpublish course",
  deletePublicationMessage:
    "Are you sure you want to unpublish the course for $account?",
  orgStructure: "Department",
  type: "Type",
  downloadTemplate: "Template Download",
  published: "Published",
  scorm: "SCORM",
  quiz: "Quiz",
  staticFile: "Pdf",
  editPublication: "Edit publication",
  pleaseDefineCourseContent:
    "Please define the course content before publishing it.",
  usernameMissing: "Username missing",
  usernameError: "Username can only contain alphanumeric characters",
  emailMissing: "E-Mail missing",
  emailError: "Not a valid E-mail address",
  passwordMissing: "Password missing",
  passwordError:
    "Password has to contain at least 8 characters, an uppercase and a lowercase letter",

  registrationKeyManagement: "Registration Keys",
  registrationKeyManagementDetail: "Registration Key Management",

  addRegistrationKey: "Add Registration key",
  registrationLink: "Registrierungslink",
  createKey: "Create Key",
  user_upload_maximum: " Up to 1000 users per upload",

  userState: "User State",
  userStateDetail: "State of access permit and mandatory courses.",
  testsPassed: "Test passed",
  access: "Access",
  downloadReport: "Report",
  success: "Successfully saved!",
  warning: "Warning!",
  error: "Error!",
  removedFromProgram: "The course has been removed from your program",
  addedToProgram: "The course has been added to your program",
  courseCreated: "The course has been created",
  courseNotCreated: "The course has not been created",
  fillUpFields: "Please fill up the required fields",
  cookieNotAvailable:
    "Your login information is not available or too old, please log in again",
  courseNotFinished: "The course did not provide any completion information",
  courseAborted: "The course has been aborted",
  correctAnswer: "Correct answer",
  wrongAnswer: "Wrong answer",
  courseStarted: "The course has been started",
  testFailed: "Test failed",
  courseCompleted: "The course has been successfully completed",
  imageTooLarge: "The image should be less than 2mb large",
  wrongFormat: "Wrong format",
  passwordReset:
    "A link to reset the password has been sent to the E-Mail address.",
  passwordChanged: "The password has been changed",
  accountAdded: "The account has been added",
  accountUpdated: "The account has been updated",
  accountDeleted: "The account has been deleted",
  userImported: "Users have been imported",
  userNotImported: "Users haven't been imported",
  loggingConfigSaved: "The logging config has been saved",
  userDeleted: "The user has been deleted",
  userAdded: "The user has been added",
  userUpdated: "The user has been updated",
  GDPRLoaded: "GDPR is loaded",
  cookiesDisabled: "You have disabled cookies",
  wrongCredentials: "Wrong credentials",
  fileUploaded: "Your file has been uploaded",
  courseContentUpdated: "The course content has been updated",
  coursePublished: "The course was published",
  courseUnPublished: "Course unpublished successfully",
  serverUnreachable: "The server is unreachable",
  courseContentAdded: "Course content added.",
  user_upload_exceeded:
    "Too many Users: up to 1000 users per upload are supported.",

  selectAccounts: "Select account",
  selectAccountToContinue: "Select an account to continue",
  accountOverview: "Account overview",
  users: "Users",
  courses: "Courses",
  coursesCompleted: "Courses completed",
  completedCoursesPerUser: "Completed courses per user",
  learningHours: "Learning hours",
  learningHoursPerUser: "Learning hours per user",
  courseOverview: "Course overview",
  completed: "Completed",
  assigned: "Assigned",
  courseDetails: "Course details",
  details: "Details",
  selectCourse: "Select course",
  type: "Type",
  dueDate: "Due date",
  mandatory: "Mandatory",
  completionRate: "Completion rate",
  averageScore: "Average score",
  duration: "Duration",
  averageDuration: "Average duration",
  account: "Account",
  accountName: "Account Name",
  accounts: "Accounts",
  active: "Active",
  admin: "Admin",
  all: "All",
  not_found: "Page not found",
  assessmentStatistics: "Assessment statistics",
  assignedUsersOnly: "Assigned users only",
  autoIndividual: "Auto, individual",
  cancel: "Cancel",
  cardImage: "Card image",
  close: "Close",
  completed: "Completed",
  confirm: "Confirm",
  reset: "Reset",
  country: "Country",
  course: "Course",
  courseDescription: "Course description",
  courseDocuments: "Course documents",
  courses: "Courses",
  dataProtection: "Data Protection",
  defaultLanguage: "Default language",
  deleteText: "Are you sure you want to delete ",
  details: "Details",
  dragAndDrop: "Drag & drop or click to add",
  dueDate: "Due date",
  email: "Email",
  empty: "Empty",
  exit: "Exit",
  expiresOn: "Expires on",
  fileImport: "File to import",
  image: "Image",
  inactive: "Inactive",
  language: "Language",
  logo: "Logo",
  mandatory: "Mandatory",
  monthsLabels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  myAccount: "My account",
  myProfile: "My profile",
  myProgram: "My program",
  myRoles: "My roles",
  never: "Never",
  no: "no",
  none: "None",
  notFound: "Not found",
  phoneNumber: "Phone number",
  playImage: "Play image",
  publishingExpiresOn: "Publishing expires on",
  publishTo: "Publish to",
  reports: "Reports",
  save: "Save",
  search: "Search",
  status: "Status",
  structureItem: "Structure item",
  termsAndConditions: "Terms & Conditions",
  unlimited: "Unlimited",
  update: "Update",
  user: "User",
  username: "Username",
  users: "Users",
  visibility: "Account visibility",
  yes: "yes",
  zipCode: "Zip code",
  courseDisclaimerHeader: "Course Disclaimer",
  courseDisclaimerText:
    "You can find further courses under {0}. If you are starting or subscribing to a course, it will get added to you programm automatically.",
  courseDisclaimerButton: "Don't show again",

  info: "Info",
  error: "Error",
  alert: "Alert",
  criticalAlert: "Critical Alert",
  navigation: "Navigation",
  system: "System",
  note: "Note",
  userProfile: "User profile",

  userId: "User ID",
  customId: "Custom ID",
  organizationalStructure: "Organizational Structure",
  created: "Created",
  new: "New",
  noResults: "No Results",
  noData: "No data available",
  uploadFile: "Upload file",

  id: "ID",
  name: "Name",
  delete: "Delete",
  append: "Append",
  edit: "Edit",
  format: "Format",

  years: "Years",
  months: "Months",
  weeks: "Weeks",
  days: "Days",
  seconds: "Seconds",
  minutes: "Minutes",
  hours: "Hours",

  date: "Date",
  message: "Message",
  fax: "Fax",

  genericError:
    "An error occured, try reloading the page. If the error persists contact your system administrator.",
  action: "Action",
  // TODO
  roles: "Roles",
  properties: "Properties",
  property: "Property",
  assignUserProperty: "Assign property to user",
  userProperties: "User properties",
  addTagToUser: "Add property to user",
  showMoreFilters: "Show more filters",

  assigned: "Assigned",
  unassigned: "Unassigned",
  assignedOrUnassigned: "Assigned/Unassigned",
  emailAboutChange: "Send users a notification E-Mail",
  showLessFilters: "Show less filters",
  clearFilters: "Clear filters",
  no_changes_assignment_button: "No Changes",
  assignment: "Assignment",

  upload_picture: "Upload Image",
  take_picture: "Take Picture",
  access_card: "Access Card",

  assign_course: "Assign Course",
  add_assignment_to_users: "assign to $users",
  remove_assignments_from_users: "remove assignment from $users",
  update_assignments_for_users: "update $users assignments",
  create_property: 'Create new property "$property"',
  non_mandatory: "Non-mandatory",
  department: "Department",
  user_details: "User Details",
  download_file: "Download File",
  your_browser_not_support_this_content:
    "Your browser does not support viewing this content.",
  browser_not_support_pdf:
    "Your browser can't display pdf files.\n Scroll down to download the pdf instead.",
  upload_file: "Upload File",
  deleteDocument: "Delete Document",
  deleteDocumentConfirmation: "Are you sure you want to delete $document?",
  video_unsupported: "Your browser does not support HTML5 video.",
  confirm: "Confirm",
  confirmed: "Confirmed",
  multiselect: "Allow multiple property selection",
  profile_image: "Profile Image",
  account_details: "Account details",
  mandatory_user_properties: "Mandatory user properties",
  published_courses_for_account: "Published Courses",
  select_property_first: "Please select a property",
  selected_property_already_mandatory:
    "The selected property is already mandatory.",
  remove_assigned_properties: "Remove assigned properties",
  property_type: "Property type",

  confirm_remove_assigned_properties:
    "Please confirm that you want to remove all currently assigned properties of type $type. This action cannot be undone.",
  tags_removed_from_users:
    "All assigned property of type $type have been removed.",

  add_mandatory_user_property: "Add mandatory user property",

  upload_certificate: "Upload Certificate",

  delete_certificate: "Delete Certificate",
  confirm_delete_certificate:
    "Are you sure you want to delete your uploaded certificate $certificateName?",

  certificateName: "Certificate name",
  issuedFrom: "Issued from",
  ectsPoints: "ECTS-Points",
  ects: "ECTS",
  completedInPercent: "Completed in percent",

  issuedAt: "Issued at",
  receivedAt: "Received at",
  validUntil: "Valid until",
  unlimitedValidity: "Unlimited Validity",

  uploadedCertificates: "Certificates",

  // TODO

  drag_drop_steps: "Drag and drop steps from the left to add them to a lesson.",
  add_lesson: "Add lesson",

  minCompletion: "Minimum percentage of points to complete lesson",
  randomStepOrder: "Randomize the order of steps",
  fixedViewExecutionOrder: "Randomize the order of pages",
  allowBackNavigation: "Allow back navigation",
  maxTimeForCompletions: "Maximum time for completion in minutes",
  maxRetries: "Maximum number of retries",
  maxNumberOfCompletions: "Maximum number of runs to improve the score",

  quiz: "Quiz",
  text: "Text",
  multimedia: "Video",
  pdf: "Pdf",
  inputText: "Text input",
  inputDate: "Date input",

  quiz_description:
    "Ask a question and allow the user to choose from multiple answer options.",
  text_description:
    "Show a text to the user and let them optionally confirm to the content of it.",
  multimedia_description:
    "Show a video to the user and let them optionally confirm that they have viewed the content",
  pdf_description:
    "Show a video to the user and let them optionally confirm that they have viewed the content",
  inputText_description: "Let the user input some text.",
  inputDate_description: "Let the user input a date.",

  lessons: "Lessons",
  new_lesson: "New Lesson",

  settings: "Settings",
  requiresCompletion: "This step has to be completed to continue",
  requiresInteraction: "The user has to confirm they viewed this step",
  isEditable: "The user can edit this step",
  points: "Punkte",
  clickToEdit: "(click to edit)",
  choiceSettings: "Choice settings",
  choice: "Choice",
  choiceText: "Choice $index text",
  choiceExplanation: "Choice $index explanation",
  choiceIsCorrect: "Correct choice",
  addChoice: "Add choice",
  deleteChoice: "Delete choice",
  uploadVideoFile: "Upload video file",
  discardChanges: "Discard Changes",
  addInputLabel: "(add a label)",
  inputType: "Input type",
  week: "Week",
  month: "Month",
  year: "Year",
  label: "Label",
  minDate: "Minimum date",
  maxDate: "Maximum date",
  oneLine: "Single Line",
  multipleLine: "Multiple Lines",
  rows: "Rows",
  minLength: "Minimum length",
  maxLength: "Maximum length",
  showWordLimit: "Show character limit",
  // TODO: translate

  lessonFailedNoMoreRetries:
    "You have failed the lesson after $retries attempts.",
  lessonFailedMoreRetries:
    "You have $retries more attempts to finish the lesson.",
  lessonFailedOneMoreRetries: "You have one more attempt to finish the lesson.",

  // TODO:
  registrationKeyAlphanumeric:
    "Registration Key (alphanumeric, min. {length} letters)",
  registrationKeyMinimumLength:
    "Registration key has to have at least {length} letters",
  chooseAnAccountToContinue: "Select an account to continue",

  help: "Help",
  alreadyRegistered: "Already registered",
  additionalDetails: "Additional details",
  additionalDetailsSave: "Confirm",
  upload: "Upload",
  uploadVideo: "Upload video",
  uploadPdf: "Upload pdf",
  step: "Step",
  flexView: "Page",
  steps: "Steps",
  flexViews: "Pages",
  lesson: "Lesson",
  dragInThisAreaToDelete: "Drag and drop elements in this area to delete them",
  editor: "Editor",
  baseLanguage: "Base language",
  translation: "Translation",
  editCourse: "Edit course",
  editTranslation: "Edit translations",
  additionalDetailsSave: "Confirm",
  dragAndDropCertificate: "Drag & drop PDF file here or click to add",
  userProfileV2: "User profile V2",
  certification: "Certification",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  fulfilledInPercent: "Fulfilled in percent",

  systemLogs: "System logs",
  systemConfigurationDetail: "Configure system settings",
  systemConfiguration: "System configuration",
  views: "Views",
  apis: "APIs",
  apisDetail: "Manage API Keys",
  logs: "Logs",
  viewsDescription: "Visibility of the element in the respective view",
  courseFormatDescription: "Available course formats for defining the course",
  accessCard: "Access card",
  downloadCertification: "Download Certification",

  pointTracking: "Display points",
  repeatable: "Repeatable",
  completionShowScore: "Show score after completion",

  plusNewTag: "+ New Tag",
  orSeparator: "or",
  fileName: "File name",
  fileSize: "File size",
  contentManagerPicker: "Content manager",
  errorSavingChanges: "An error occured",
  errorSavingChangesBody:
    "An error occured while saving your changes. Check your internet connection and try again in a few minutes.",
  retry: "Retry",
  editLesson: "Edit lesson",
  templates: "Templates",
  courseStructure: "Course structure",
  statistics: "Statistics",
  saveChangesTitle: "Save changes",
  saveChangesBody:
    "You have some unsaved changes.\nShould these changes be saved or discarded?",
  flexLessonLocked: "Lesson has to be finished before the user can continue",
  flexSubset:
    "Number of pages that are randomly chosen from all pages in the lesson.",
  curatorName: "Curator name",
  curatorCompany: "Curator company",
  curatorDescription: "Curator description",
  courseCompletedTitle: "Course completed title",
  courseCompletedSubtitle: "Course completed subtitle",
  courseCompletedNote: "Course completed body",
  completionShowScore: "Show score on course completion view",
  failedCourseInformation: "Text to show when the user fails the course",
  pinPage: "Always show this page at the start or end of lesson",
  start: "Start",
  end: "End",
  courseDuration: "Course duration in minutes",
  randomizeAnswers: "Randomize Answers",
  closeEditor: "Close editor",
  courseNotEditable:
    "This course is already in use and thus only translations can be edited.",
  flex: "Flex",
  event: "Event",
  instructions: "Instructions",
  isOnlineEvent: "Online event",
  eventLocation: "Location",
  eventLink: "Event link",
  selfRegistration: "Self-registration",
  attendanceConfirmation: "Presence",
  passedConfirmation: "Pass",
  mark: "Mark",
  trainer: "Trainer",
  attendanceAndGrades: "Attendance and evaluation",
  onlyIfMandatoryCourse: "If course assigned",
  allUsersInAccount: "All users in the account",
  dateManagement: "Date management",
  dateManagementDetail: "Manage event dates and results",
  courseFilter: "Course filter",
  fromTo: "From - to",
  capacity: "Capacity",
  unlimitedCapacity: "Unlimited capacity",
  invalidatePreregistration:
    "Invalidate pre-registrations in hours before the event",
  allowPreregistration: "Allow pre-registration",
  newDate: "New Date",
  editDate: "Edit Date",
  preenrollments: "Pre-Registration",
  enrollments: "Registration",
  preregistrationDate: "Pre-registration $date",
  registered: "Registered",
  notRegistered: "Not registered",
  registeredAndNotRegistered: "Registered / Not registered",
  selectCourseToContinue: "Select a course to continue",
  changesSavedSuccessfully: "Changes saved successfully",
  cancelRegistrationTitle: "Cancel registration",
  cancelRegistrationBody:
    "Are you sure you want to remove $name's registration for the following date?",
  date: "Date",
  dates: "Dates",
  unregister: "Cancel registration",
  changeRegistrationTitle: "Change Registration",
  currentRegistration: "$name is already registered for the following date",
  changeRegistrationBody:
    "Do you want to cancel this registration and instead create a registration for the following date?",
  preregistrationAvailableRegistrations: "Pre-registrations / available seats",
  seatsLeft: "available seats",
  registrationTitle: "Registration",
  registrationBody:
    "By registering the user to $course a mandatory assignment for that course will be created for the user.",
  register: "Register",
  myDates: "My appointments",
  dateDetails: "Date details",
  attendees: "Attendees",
  fractionOfTotal: "$fraction of $total",
  registrations: "Registrations",
  link: "Link",
  notPassed: "Not passed",
  passed: "Passed",
  attended: "Attended",
  notAttended: "NotAttended",
  youAreRegisteredFor: "You are registered for the following date: ",
  unlimitedSeats: "Unlimited capacity",
  otherDates: "Other dates",
  managerWillAssignDate: "Your manager will assign a date for you.",

  courseCompletionsPerUser: "Course completions per user",
  courseAssignmentsPerUser: "Course assignments and completions",
  userState: "User state",
  accountStatistics: "Account statistics",
  reportExcelDownloadBtn: "Download excel report",
  reportDisplayBtn: "Display",
  covidReport: "Covid report",
  reportExcelCourseCompletion: "Course assignment and completion report",
  reportExcelPracticalTests: "Events report",
  listOfAccomplishedCourses:
    "List of mandatory and optional course accomplishments by user",
  listOfAssignedCourses:
    "List of mandatory and optional course assignments, completions and locks per user",
  reportUserState:
    "List of user access state and state of assigned mandatory courses",
  descriptionCovidReport: "List of COVID vaccination certificates",
  descriptionCourseAssignmentsAndCompletion:
    "List of course assignments and current course completion status.",
  descriptionEventReport: "List of event registration and attendance",
  descriptionAccountStatistics:
    "Account specific KPI's and statistics for courses published for selected account",
  userRoleAssignments: "User role assignments",
  listOfRolesPerUser: "List of roles assigned to user by account",

  copied: "Link copied to clipboard",
  covidCertificates: "Vaccination Certificates",
  covidCertificatesDetail: "Manage vaccination and test certificates",
  covidInstructions: "COVID-19 instructions",
  covid: "COVID-19",
  addCertificate: "Add certificate",
  scanQrCode: "Scan QR-Code",
  scanQrCodeDescription:
    "The folowing QR-Codes can be scanned or uploaded:  Vaccination certificates, PCR and antigen-tests, certificates of recovery",
  openFile: "Open file",
  openImage: "Open image file",
  validFrom: "Valid since $date",
  validFor: "Valid on the $date",
  vaccinationCertificatesValid:
    "Your certificates are compliant with the current rules",
  vaccinationCertificatesInvalid:
    "Your certificates are not compliant with the current rules",
  certificateInvalid: "This certificate is invalid",
  certificateExpired: "This certificate is expired",
  updatedOn: "Updated on $date",
  uploadingCertificate: "Uploading certificate",
  certificateUploadError: "An error occured whild uploading the certificate",
  certificateNameMismatch: "The name of the certificate does not match",
  certificateBirthdayMismatch: "The birthday of the certificate does not match",
  certificateExists: "This certificate already exists",
  certificateUploaded: "Certifcate uploaded successfully",
  instructions: "Instructions",
  accessRules: "Access rules",
  qrCode: "QR-Code",
  certificateDeleted: "Certificate deleted",
  deleteCertificateConfirmation:
    "Are you sure you want to delete this certificate?",
  covidInstructionsLabel: "Instruction in the system default language $lang.",
  cameraNotAllowedError:
    "Camera access has been denied. Reload the page to allow access or allow access in the browser settings",
  cameraNotFoundError: "No camera found, use the upload functionality instead.",
  cameraNotReadableError:
    "Could not access the camera - please make sure that no other application is using the camera.",
  cameraNotAvailable:
    "Your browser does not support camera access. Please use the upload functionality instead.",
  qrCodeUploadError:
    "QR code could not be read, please check that the image contains only one QR code, and that the QR code is readable. If the problem persists, try a higher resolution image.",
  confirmCovidRulesManuallyTitle: "COVID-19 - visual status check",
  confirmCovidRulesBody:
    "I confirm that the documents provided by $user fulfill the current COVID-19 rulings and are valid for the current day.",
  activateApis: "Activate Apis",
  allowApiManagement: "Allow api management",
  allowedApis: "Allowed Apis",
  volume: "Volume in req/day",
  throttle: "Throttle in req/min",

  keys: "Keys",
  key: "Key",
  keyName: "Name",
  keyPurpose: "Purpose",
  createApiKey: "Add Api Key",
  keyCopiedToClipboard: "Successfully copied key to clipboard.",
  deleteKey: "Delete key",
  deleteKeyMessage:
    "Are you sure you want to delete the key '$name' for '$purpose'?",
  changeKey: "Edit key",
  keyCreatedSuccessfully: "The api key has been created successfully",
  api: "Api",
  addApi: "Add Api",
  editApi: "Edit Api",
  example: "Example",
  apiDetails: "Api details",
  notAvailable: "Not available",
  method: "Method",
  requestBody: "Request body",
  responses: "Responses",
  parameters: "Parameters",
  manualCheck: "Manual Check",
  qGateTitle: "Quality check of user data",
  qGateDescription:
    "If the Q-Gate is activated, gate control is requested to check the quality of the user data and might also add additional details to the user data.",
  subcontractorTitle: "Show subcontractor tab",
  subcontractorDescription:
    "Allows adding subcontractors to an account as well as assigning users to subcontractors.",
  subcontractors: "Subcontractor",
  subcontractors: "Subcontractors",
  addSubcontractor: "Add subkontraktor",
  editSubcontractor: "Edit subkontraktor",
  street: "Street",
  deleteSubcontractor: "Delete subcontractor",
  deleteSubcontractorBody: "Company: $name",
  deleteSubcontractorHasReference:
    "Subcontractor already has users assigned to it, and thus cannot be deleted.",
  accessRegistration: "Registration of access",
  accessFrom: "Access from",
  accessTo: "probable end of access",
  birthplace: "Place of birth",
  nationality: "Nationality",
  car: "Car",
  carPlate: "Numberplate",
  socialSecurity: "Social security number",
  workPermit: "Workpermit until",
  confirmData:
    "I confirm the completeness and correctness of the entered data.",
  accessRegistration: "Access registration",
  registeredOn: "Registered on",
  registeredBy: "Registered by",
  isOnline: "Online",
  coursePreRegistrationUsedFrom:
    'Total number of pre-registrations for "{courseName}" - {preRegTotal} about to be cancelled: {preRegAboutToCancel}',
  notEnoughCapacityReload:
    "Not enough free capacity. The current capacities are being reloaded.",
  editUserData: "Edit user data",
  dateSpecificInstructions: "Event specific instructions",
  preRegistrationClosed: "Pre-registration are expired for this date",
  preRegistrationNotificationsTitle:
    "Send E-Mails about expiring pre-registrations",
  preRegistrationNotificationsDescription:
    "Account owner will be informed existing registrations and pre-registrations expiring during the next days",
  automaticAssignments: "Automation",
  infoOnly: "Info only",
  automated: "Automated",
  assignAutomaticallyTo: "Assign automatically to",
  allUsers: "All users",
  usersWithAssignedProperty: "Users with assigned property",
  assignmentType: "Assignment type",
  optionally: "Optionally",
  mandatory: "Mandatory",
  exactDate: "Exact date",
  relativeDateOffsetDays: "Relative (offset days)",
  emailNotification: "E-Mail notification",
  selectPropertyMandatory: "Select property",
  setAllSelected: "Set all selected",
  nSelected: "{count} selected",
  publishedUnpublished: "Published/unpublished",
  published: "Published",
  unpublished: "Unpublished",
  noneInfoAutomated: "None/info/automated",
  scope: "Scope",
  tagged: "Tagged",
  notification: "Notification",
  selectAll: "Select all",
  passwordResetLinkInactive: "This password reset link is not valid anymore.",
  requestNewLink: "Request new reset link",
  testQuestionnaire: "Test questionnaire",
  testQuestionnaireDetail: "Manage test questionnaires for events",
  newQuestionnaire: "New questionnaire",
  newTestQuestionnaire: "New test questionnaire",
  addQuestion: "Add question",
  editTestQuestionnaire: "Edit test questionnaire",

  requiredPoints: "Required points",
  questions: "Questions",
  question: "Question",
  answer: "Answer",
  responseDocumentation: "Response documentation",
  ko: "Ko",
  failedPassed: "Failed/passed",
  failedPassedComment: "Failed/passed + comment",
  assignedCourses: "Assigned courses",
  noCoursesAssigned: "No courses assigned",
  questionnaire: "Questionnaire",
  questionnaireLocked: "Questionnaire locked",
  born: "born",
  examinee: "Examinee",
  totalResult: "Total result",
  comment: "Comment",
  done: "Done",
  pointsRequired: "Points required",
  correct: "Correct",
  wrong: "Wrong",
  locked: "Locked",
  locks: "Locks",
  lockedAtCest: "Locked at (CEST)",
  unlockCourse: "Unlock course",
  unlockCourseMessage:
    "Are you sure you want to unblock course {0} for user {1} ?",
  allowedNumberOfAttempts: "Allowed number of attempts",
  noDates: "There are currently no dates",
  testResults: "Test results",
  pointScore: "{points} points",
  markScore: "Mark: {mark}",
  registrationLocked: "Registration locked",
  registrationLockedMessage:
    "The results of the event are currently being evaluated. Changing registration will be possible when the evaluation is finished",
  properties: "Properties",
  propertiesDetail: "Manage user and course properties",
  types: "Types",
  addTagType: "Add type",
  editType: "Edit type",
  create: "Create",
  deleteTagType: "Delete tag type",
  confirmDeleteTagTypeName: "Are you sure the type {name} should be deleted?",
  relatedProperties: "Related properties",
  addTagValue: "Add Property",
  deleteTagValue: "Delete property",
  confirmDeleteTagValueName:
    "Are you sure the property {name} should be deleted?",
  editTagValue: "Edit property",
  purpose: "Purpose",
  uploadLabel: "Upload label",
  tagLabelSizeError:
    "The selected image file size is too big. Only images up to 20Kb are supported",
  fileSizeError: "This file is too large, a maximum of {sizeMb} Mb is allowed",
  passed: "Passed",
  failed: "Failed",
  emergencyContact: "Emergency contact",
  emergencyContactPhone: "Emergency contact phone number",
  userHasNoAccount: "This user does not have an account",
  driveInPermit: "Drive-in permit",
  downloadRegistrationForm: "Registration form",
  accessStartDate: "Access start date",
  today: "Today",
  tomorrow: "Tomorrow",
  dayAfterTomorrow: "Day after tomorrow",
  tests: "Tests",
  createTest: "Create test",
  planned: "Planned",
  myTests: "My tests",
  houseNumber: "Houseno.",
  actionResult: "Action",
  actionResultKpi: "KPI",
  missedAfter: "Considered missed after",
  testPerson: "Test person",
  createAndDocument: "Create & document",
  eob: "EOB",
  testDate: "Test date",
  test: "Test",
  testManagement: "Test management",
  testManagementDetail: "Manage test types",
  addTestType: "Add test type",
  testType: "Test type",
  testTeam: "Test team",
  storage: "Storage",
  validity: "Validity",
  editTestType: "Edit test type",
  testValidityInDays: "Test validity (days)",
  retentionPeriod: "Retention period",
  createTestType: "Create test type",
  authorizedTesters: "Authorized testers",
  testAlertReceivers:
    "Alert receivers on positive result (E-Mail adresses, comma separated)",
  documentTestResult: "Document test result",
  confirmCorrectnessTestResultData: `Please confirm the correctness of the entered Data. 
  After confirmatiom not more changes can be made.`,
  testCreated: "Test create",
  testResultDocumented: "Test result saved",
  result: "Result",
  expiresIn: "Expires in",
  deleteTestType: "Delete test type",
  deleteTestTypeMessage: "Are you sure you want to delete the test type {0}?",
  positive: "Positive",
  negative: "Negative",
  testResult: "Test result",
  open: "Open",
  testReportHeader: "Test schedule and results",
  testReportBody:
    "Overview of scheduled tests and test results by user and date",
  display: "Display",
  testReportEnterData: "Please select a start/end date and a test type",
  startDate: "Start date",
  endDate: "End date",
  searchUser: "Search user",
  completedAt: "Completed",
  enterAtLeastTwoLetters: "Enter at least two letters",
  testsLimitExceeded:
    "Your query has too many results, display is restricted to 2000 results.",
  missed: "Missed",
  scheduled: "Planned",
  saveAndApply: "Save & Apply",
  errorApplyingRule: "Error applying rule. Try again in a few minutes.",
  triggerAllSelected:
    "Trigger automation for existing users in the selected accounts.",
  assignmentRuleApplied: "Assignment rules applied.",
  selectAccountWithAutomationToContinue:
    "Select at least one account with automation rule to be triggered.",

  automationCenter: "Automation center",
  automationCenterDetail: "Create and manage automated rules.",
  newRule: "New rule",
  topic: "Topic",
  newTestCenterAutomationRule: "New automation rule",
  global: "Global",
  accountSpecific: "Account specific",
  selectTemplate: "Select Template",
  lastRun: "Last run",
  rule: "Rule",
  reportIsBeingCreated: "The report is being created.",
  selectedAccounts: "Selected accounts",
  mo: "Mo",
  tu: "Tu",
  we: "We",
  th: "Th",
  fr: "Fr",
  sa: "Sa",
  su: "Su",
  selectTopic: "Select topic",
  deleteAutomationRule: "Delete automation rule",
  deleteAutomationRuleConfimration:
    "Are you sure you want to delete the rule {rule}?",
  editAutomation: "Edit automation rule",
  triggerAt: "Trigger at",
  reportIsBeingCreated: "The report is being created.",
  addCoverPhoto: "Add cover photo",
  confirmDelete: "Confirm delete",
  confirmDeleteTest:
    "Are you sure you want to delete the test {type} on {date} for {name}?",
  miscellaneous: "Miscellaneous",
  addCoverPhoto: "Add cover photo",
  triggeredBy: "Triggered by",
  criticality: "Criticality",
  logDetails: "Log details",
  lastHour: "Last hour",
  last24Hours: "Last 24 hours",
  last3Days: "Last 3 Days",
  lastWeek: "Last Week",
  accountsImported: "Accounts imported",
  importMoreAccounts: "Import more accounts",
  backToOrganization: "Back to organization structure",

  retention: "Retention",
  maximumAllowed: "Maximum allowed is {maximum}.",
  completionValidity: "Validity duration",
  completionValidityinCertificate: "View validity duration in certificate",
  useTranslationsToEdit: "Please use the translations editor",
  hardwareRestrictions: "Device restrictions",
  hardwareRestriction: "Device restriction",
  hardwareRestrictionsDetail:
    "Manage on which devices certain courses may be attended",
  installed: "Installiert",
  notInstalled: "Nicht installiert",
  installKeyOnThisDevice: "Install key on this device",
  removeKeyOnThisDevice: "Remove key from this device",
  add: "Add",
  createKey: "Create Key",
  editKey: "Edit Key",
  accessCourseAccount: "Access (Course: Accounts)",
  deselectAll: "Deselect all",
  hardwareRestrictionKeyRequiredHeader: "Restricted Course",
  hardwareRestrictionKeyRequiredBody:
    "This course can only be run on specific Computers. If this course should run on this device, please contact your supervisor.",
  deleteSession: "Delete session",
  deleteSessionConfirmation:
    "Are you sure you want to permanently delete the session?",
  deleteSession: "Delete session",
  startedCourses: "Started courses",
  startedOn: "Started on",
  courseSessionDeleted: "Session was deleted!",
  noCourses: "No courses",
  startedBy: "Started by",
  noUsers: "No users",
  deleteAllSessions: "Delete all sessions",
  deleteAllSessionsConfirmation:
    "Are you sure you want to permanently delete all sessions?",
  uploading: "Uploading",

  incomplete: "Incomplete",
  expired: "Expired",
  due: "Due",

  loginRedirects: "Login redirects",
  createLoginRedirect: "Create login redirect",
  redirectFromLogin: "Redirect to registration without showing the login page",
  installLoginRedirectOnThisDevice: "Install login redirect on this device",
  redirects: "Redirects",
  removeLoginRedirectOnThisDevice: "Remove login redirect from this device",
  localLoginKeyNoLongerExists:
    "The locally installed login redirect no longer exists and was removed",
  localLoginKeyUpdated: "The locally installed login redirect has been updated",
  systemConfigurationError:
    "System is wrongly configured. Contact the system administrator. ",
  addProperty: "Add property",
  confirmDeleteName: "Are you sure you want to delete {name}?",
  selectView: "Select view",
  addSection: "Add section",
  editSection: "Edit section",
  notTested: "Not tested",
  confirmDeleteQuestionnaireSection:
    "Please confirm you want to delete the section {0}. All questions in this section will be moved to the default section.",
  nOfMQuestionsEdited: "{n} of {m} questions edited",

  recogniseCourseForName: "Recognise course for {name}",
  courseRecognitions: "Course recognitions",
  reason: "Reason",
  recognise: "Recognise",
  revoked: "Revoked",
  recognised: "Recognised",
  selectedCourseNotPublishedForUser:
    "The selected course is not published for the user",
  confirmCourseRecogntionForUser:
    "Please confirm you want to recognise {course} for {user}.",
  confirmRevokeCourseForUser:
    "Bitte bestätigen sie, dass sie {course} für {user} aberkennen wollen.",
  revocation: "Revocation",
  revoke: "Revoke",
  fieldIsRequired: "{field} has to be entered",
  fieldsAreMissing: "The following fields are required: {fields}",
  automatedLogout: "Logout after course completion",

  logout: "Logout",
  continue: "Continue",
  courseFinishedDoYouWantToLogout:
    "The course is finished. Do you want to logout or continue? You will be automatically logged out in {seconds}.",
  file: "File",
  monitoring: "Monitoring",
  fraudDetection: "Fraud detection",
  fraudDetectionDetail: "Identification of suspicious activities and anomalies",
  falsePositive: "False positive",
  inProcess: "In process",
  severity: "Schweregrad",
  lastNDays: "Last {n} days",
  createPreconfigurationLink: "Create configuration link",
  createPreconfigurationLinkDescription:
    "The configuration link saves the current device restrictions and login redirects on each device it is executed.",
};
